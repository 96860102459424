import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../API";

function LanguageToogle(props) {
    const [toggleState, setToggleState] = useState("off");
    const [t, i18n] = useTranslation('common');

    const changeLanguage = (data) => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        const emplyoeeId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const language = data
        console.log("emplyoeeId: " + emplyoeeId + ", language: " + language)
        console.log("/users/changeLanguage")

        Api({
            method: 'post',
            url: '/users/changeLanguage',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

              EmployeeId: emplyoeeId,
              Language: language
            }
          }).then(response => {
            if (response.data.status == 201) {
                window.location.reload();
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
            }
            else if (response.data.success == 1) {

            } else {
                
            }
      
            console.log(response.data);
          }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
          });
    }

    function toggle() {
        setToggleState(toggleState === "off" ? "on" : "off");
        // localStorage.setItem('lang', 'en')


        if (toggleState === "on") {
            console.log("Selected Language en")
            i18n.changeLanguage('en')
            localStorage.setItem('lang', 'en')
            changeLanguage('en')
        } else {
            console.log("Selected Language jap")
            i18n.changeLanguage('jap')
            localStorage.setItem('lang', 'jap')
            changeLanguage('jap')
        }

    }

    useEffect(() => {
        var selectedLanguage = localStorage.getItem("lang")
        console.log("Selected Language " + selectedLanguage)


        if (selectedLanguage === "jap") {
            // i18n.changeLanguage('jap');
            // localStorage.setItem('lang', 'jap')
            toggle();
        } else {

            i18n.changeLanguage('en')
        }

    }, []);

    return <div className={`switch ${toggleState}`} onClick={toggle} />;
}

export default LanguageToogle;
