import React, { useState, useEffect } from "react";
import Api from "../../../API";
import AddNewAsset from "./AddNewAsset/AddNewAsset";
import AddNewCategory from "./AddNewCategory/AddNewCategory";
import CreateNewUser from "./AddNewUser/CreateNewUser";
import HistoryTable from "./HistoryTable/HistoryTable";
import { useTranslation } from 'react-i18next';

const AdminPanel = () => {
  const [t, i18n] = useTranslation('common');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [addModalCategoryOpen, setAddModalCategoryOpen] = useState(false);

  const [emplyoeeCount, setEmplyoeeCount] = useState(0)
  const [assetCount, setAssetCount] = useState(0)
  const [categoryCount, setCategoryCount] = useState(0)

  const ModalStatus = (status) => {
    if (status) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
    setIsModalOpen(false);
  };

  const createNewUser = () => {
    console.log("Create Clicked");
    console.log(isModalOpen);
    setIsModalOpen(true);
    console.log(isModalOpen);
  };
  const closeNewUserModal = () => {
    setIsModalOpen(false);
  };


  const addNewAsset = () => {
    console.log("Add new Clicked");
    console.log(addModalOpen);
    setAddModalOpen(true);
    console.log(isModalOpen);
  }
  const closeAddNewAsset = () => {
    setAddModalOpen(false);
  }


  const addNewCategory = () => {
    console.log("Add new Clicked");
    console.log(addModalOpen);
    setAddModalCategoryOpen(true);
    console.log(isModalOpen);
  }
  const closeAddNewCategory = () => {
    setAddModalCategoryOpen(false);
  }


  const fetchData = () => {

    const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
    const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
    const token = JSON.parse(localStorage.getItem('userDetails')).token;

    console.log("/admin/totalCount")

    Api({
      method: 'post',
      url: '/admin/totalCount',
      data: {

          LoggedInId: loggedInId,
          EmployeeIsAdmin: isAdmin,
          token: token,
        
      }
    }).then(response => {
      if (response.data.status == 201) {
        // window.open("https://www.google.com", "_self");
        localStorage.clear();
        window.location.reload();
    }
    else if (response.data.success === 1) {
        // setTableData([])
        // setTableData(response.data.data)
        setEmplyoeeCount(response.data.data.EmployeeCount)
        setAssetCount(response.data.data.AssetCount)
        setCategoryCount(response.data.data.CategoryCount)
      } else {
        alert("Something went wrong");
      }

      console.log(response.data);
    }).catch(function (error) {
      console.log(error);
      // alert("Something went wrong");
      alert(error);
    });
  }

  useEffect(() => {

    fetchData();

  }, []);


  return (
    <>
      <div className="AdminOptions">
        <ul className="adminOptionLeftSide ml-0 pl-0">
          <li>
            <HistoryTable />
          </li>
        </ul>


        <ul className="adminOptionRightSide mb-0 pl-3 pr-2">

          <li>
            <h5 className="adminData"> {t('AdminPanelData.2')}{emplyoeeCount} </h5>
          </li>

          <li>
            <h5 className="adminData"> {t('AdminPanelData.3')}{assetCount}</h5>
          </li>
          <li></li>
          <li>
            <h5 className="adminData"> {t('AdminPanelData.4')}{categoryCount}</h5>
          </li>


          <li>
            <CreateNewUser show={isModalOpen} onHide={closeNewUserModal} />
            <button className="adminButton" onClick={createNewUser}>
              {/* Create new user */}
              {t('AdminPanelData.5')}
            </button>
          </li>
          <li>
            <AddNewAsset show={addModalOpen} onHide={closeAddNewAsset} />
            <button className="adminButton" onClick={addNewAsset}>
              {/* Add new asset */}
              {t('AdminPanelData.6')}
            </button>
          </li>
          <li>
            <AddNewCategory show={addModalCategoryOpen} onHide={closeAddNewCategory} />
            <button className="adminButton" onClick={addNewCategory}>
              {/* Add new category */}
              {t('AdminPanelData.7')}
            </button>
          </li>
        </ul>
      </div>

    </>
  );
};

export default AdminPanel;
