import MaterialTable from 'material-table';
import { useState, useEffect } from 'react';
import Api from '../../../API';
import { CsvBuilder } from 'filefy';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import { Replay } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import MyAssetsMonthlyCheck from './MyAssetsMonthlyCheck';

function MyAssets() {
    const [t, i18n] = useTranslation('common');

    const [monthlyCheckModalOpen, setMonthlyCheckModalOpen] = useState(false)

    const [tableData, setTableData] = useState([
        // { AssetId: "Maruf", AssetName: "asd@gmail.com", EmployeeId: "012345", UsageStart: "Male", IsOkay: "Dhaka", AssetDetails: "Asset details" },
        // { name: "Hasan", email: "qwe@gmail.com", phone: "345", age: 15, gender: "Female", city: "CTG", cost: 1234 },
        // { name: "Shakil", email: "zxc@gmail.com", phone: "012", age: 55, gender: "Male", city: "Sylhet", cost: 1234 },
        // { name: "MHS", email: "mhs@gmail.com", phone: "123", age: null, gender: "Male", city: "Raj", cost: 1234 },
        // { name: "Maruf", email: "asd@gmail.com", phone: "012345", age: 25, gender: "Male", city: "Dhaka", cost: 1234 },
        // { name: "Hasan", email: "qwe@gmail.com", phone: "345", age: 15, gender: "Female", city: "CTG", cost: 1234 },
        // { name: "Shakil", email: "zxc@gmail.com", phone: "012", age: 55, gender: "Male", city: "Sylhet", cost: 1234 },
        // { name: "MHS", email: "mhs@gmail.com", phone: "123", age: null, gender: "Male", city: "Raj", cost: 1234 },
    ])

    const columns = [
        {
            title: t('MyAssets.1'), field: "AssetId", emptyValue: () => <em>null</em>, defaultSort: "asc", filtering: false, editable: false,
            headerStyle: { pointerEvents: "none", textAlign: "left", },
        },
        {
            title: t('MyAssets.2'), field: "Model", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
            editComponent: ({ value, onChange }) => (
                <TextField
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    multiline
                    fullWidth
                />
            ),
        },
        {
            title: t('MyAssets.3'), field: "AssetNumber", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('MyAssets.4'), field: "SerialNumber", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('MyAssets.5'), field: "Ram", emptyValue: () => <em>null</em>, filtering: false, editable: (_, rowData) => rowData && (rowData.Ram === null || rowData.Ram === "") && rowData.EditAssetFlag === 1,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('MyAssets.6'), field: "Cpu", emptyValue: () => <em>null</em>, filtering: false, editable: (_, rowData) => rowData && (rowData.Cpu === null || rowData.Cpu === "") && rowData.EditAssetFlag === 1,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('MyAssets.7'), field: "Hdd", emptyValue: () => <em>null</em>, filtering: false, editable: (_, rowData) => rowData && (rowData.Hdd === null || rowData.Hdd === "") && rowData.EditAssetFlag === 1,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('MyAssets.8'), field: "Ssd", emptyValue: () => <em>null</em>, filtering: false, editable: (_, rowData) => rowData && (rowData.Ssd === null || rowData.Ssd === "") && rowData.EditAssetFlag === 1,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('MyAssets.9'), field: "OperatingSystem", emptyValue: () => <em>null</em>, filtering: false, editable: (_, rowData) => rowData && (rowData.OperatingSystem === null || rowData.OperatingSystem === "") && rowData.EditAssetFlag === 1,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('MyAssets.10'), field: "UsageStart", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },

        {
            title: t('MyAssets.11'), field: "UsedQuantity", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('MyAssets.12'), field: "IsOkay", emptyValue: () => <em>null</em>, filtering: false, lookup: { 0: "Defect", 1: "OK" },
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('MyAssets.13'), field: "Comments", emptyValue: () => <em>null</em>, filtering: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
            editComponent: ({ value, onChange }) => (
                <TextField
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    multiline
                    fullWidth
                />
            ),
        },
        {
            title: t('MyAssets.14'), field: "Purpose", emptyValue: () => <em>null</em>, filtering: false, editable: (_, rowData) => rowData && (rowData.Purpose === null || rowData.Purpose === "") && rowData.EditAssetFlag === 1,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
            editComponent: ({ value, onChange }) => (
                <TextField
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    multiline
                    fullWidth
                />
            ),
        },
    ]

    const [selectedRows, setSelectedRows] = useState([]);


    const editTableData = (rowDetails) => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        console.log(rowDetails)
        const assetId = rowDetails.AssetId
        const model = rowDetails.Model
        const assetNumber = rowDetails.AssetNumber
        const serialNumber = rowDetails.SerialNumber

        const assetName = rowDetails.AssetName
        const assetDetails = rowDetails.AssetDetails
        const comments = rowDetails.Comments
        const isOkay = rowDetails.IsOkay
        const emplyoeeId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;

        const cpu = rowDetails.Cpu
        const ram = rowDetails.Ram
        const ssd = rowDetails.Ssd
        const hdd = rowDetails.Hdd
        const operatingSystem = rowDetails.OperatingSystem
        const purpose = rowDetails.Purpose

        console.log(assetName + " " + assetDetails + " " + comments + " " + isOkay + " ")

        console.log("AssetId: " + assetId)
        console.log("/admin/editIndividualAsset")
        Api({
            method: 'post',
            url: '/admin/editIndividualAsset',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                AssetId: assetId,
                Model: model,
                AssetNumber: assetNumber,
                SerialNumber: serialNumber,
                Comments: comments,
                IsOkay: isOkay,
                EmployeeId: emplyoeeId,
                Cpu: cpu,
                Ram: ram,
                Hdd: hdd,
                Ssd: ssd,
                OperatingSystem: operatingSystem,
                Purpose: purpose

            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                alert("Edited Successfully")
                fetchTableData()
            } else {
                alert("Something went wrong");
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    // custom export data in CSV
    const customRowExport = () => {
        var name = ""
        var date = new Date()
        console.log("My Assets List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)")
        name = "My Assets List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        new CsvBuilder(name + ".csv")
            .setColumns(columns.map(col => col.title))
            .addRows(selectedRows.map(rowData => columns.map(col => rowData[col.field])))
            .exportFile();

    }

    const exportedFileName = () => {
        var name = ""
        var date = new Date()
        console.log(date)
        console.log(date.toISOString())
        console.log(date.toISOString().slice(0, -1))
        console.log("My Assets List " + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " (" +
            date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds() + ")")
        name = "My Assets List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        return name
    }


    // adding asset to my account
    const returnFromMyslef = (data) => {
        
        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        const assetId = data.AssetId;
        const identifiable = data.IsIdentifiable;
        const emplyoeeId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;

        console.log("AssetId: " + assetId + ", EmplyoeeId: " + emplyoeeId + ", identifiable: " + identifiable);

        Api({
            method: 'post',
            url: '/assets/returnUserAsset',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                AssetId: assetId,
                EmployeeId: emplyoeeId,
                IsIdentifiable: identifiable
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                // setTableData(response.data.data)
                fetchTableData()
            } else {
                alert("Something went wrong");
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });

    }

    // fetch table Data
    const fetchTableData = () => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        console.log("/assets/userIndividualAssets")
        Api({
            method: 'post',
            url: '/assets/userIndividualAssets',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                EmployeeId: JSON.parse(localStorage.getItem('userDetails')).LoggedInId
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success === 1) {
                setTableData([])
                setTableData(response.data.data)
            } else {
                alert("Something went wrong");
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }


    //Monthly check code are following
    const showMonthlyCheckTable = () => {
        setMonthlyCheckModalOpen(true);
    }
    const closeMonthlyCheckTable = () => {
        setMonthlyCheckModalOpen(false);
        fetchTableData()
    }

    const notifyMonthlyCheck = () => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;
        
        console.log("/health/notifyMonthlyCheck")
        Api({
            method: 'post',
            url: '/health/notifyMonthlyCheck',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                EmployeeId: JSON.parse(localStorage.getItem('userDetails')).LoggedInId,
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                showMonthlyCheckTable() // showing the Monthly check Modal Form
            } else {
                closeMonthlyCheckTable() // hiding the Monthly check Modal Form
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });

    }



    const resetUsedAsset = () => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;


        console.log("/activity/resetUsedAsset")
        console.log(JSON.parse(localStorage.getItem('userDetails')).LoggedInId)

        Api({
            method: 'post',
            url: '/activity/resetUsedAsset',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                EmployeeId: JSON.parse(localStorage.getItem('userDetails')).LoggedInId,
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {

            } else {

            }
            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
        });
    }

    useEffect(() => {
        //setId(APIIDData());

        console.log("/assets/userIndividualAssets");
        console.log("EmployeeId: " + JSON.parse(localStorage.getItem('userDetails')).LoggedInId);
        notifyMonthlyCheck();
        fetchTableData();


    }, []);



    return (
        <div className="Table">

            <MaterialTable columns={columns} data={tableData} title="My Assets Information"
                editable={{
                    onRowUpdate: (newRow, oldRow) => new Promise((reslove, reject) => {
                        editTableData(newRow)
                        reslove()
                    }),
                }}

                onSelectionChange={(rows) => {
                    console.log(rows);
                    setSelectedRows(rows);
                }}


                actions={[
                    {
                        icon: () => <Replay />,
                        tooltip: "Return",
                        onClick: (e, data) => {
                            if (window.confirm('Are you sure you wish to "Return" this item?')) {
                                console.log("confirmed")
                                returnFromMyslef(data);
                                console.log(data);
                            }
                        },
                        position: "row",
                        selection: "toolbarOnSelect"
                    },
                    {
                        icon: 'download',
                        tooltip: "export data",
                        onClick: (e, data) => {
                            console.log(data);
                            customRowExport()
                        },
                    },

                ]}

                options={{
                    sorting: true, search: true, searchFieldAlignment: "right", searchAutoFocus: false, searchFieldVariant: "standard",
                    filtering: false, emptyRowsWhenPaging: false,
                    paging: true, pageSizeOptions: [10, 20, 50, 100], pageSize: 10, paginationType: "stepped", paginationPosition: "top",
                    showFirstLastPageButtons: true,
                    exportButton: true, exportAllData: true,
                    addRowPosition: "first", actionsColumnIndex: -1,
                    selection: true, showSelectAllCheckbox: true, selectionProps: rowData => ({
                        color: "primary"
                    }),
                    grouping: false,
                    columnsButton: true,
                    rowStyle: (data, index) => data.IsOkay == 0 ? { background: "#FDD2BF", wordWrap: 'break-word', } : index % 2 === 0 ? { background: null, wordWrap: 'break-word', } : { background: "#EEFEFF", wordWrap: 'break-word', },
                    headerStyle: {
                        position: "sticky",
                        top: "0",
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        width: "0rem",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        backgroundColor: "#ADEFD1FF",
                        //backgroundColor: "#039be5",
                    },
                    maxBodyHeight: "70vh",
                    tableLayout: "fixed",
                    exportFileName: exportedFileName(),
                    // rowStyle: {
                    //   wordWrap: 'break-word',
                    // },
                    // cellStyle: {
                    //   width: 20,
                    //   maxWidth: 20
                    // }
                    // editCellStyle: {
                    //   maxWidth: 5,
                    //   wordBreak: "break-word",
                    //   whiteSpace: 'normal',
                    //   wordWrap: 'break-word'
                    // }
                }}

                localization={{
                    header: {
                        actions: t('ActionTitle.1')
                    }
                }}

            />

            <MyAssetsMonthlyCheck show={monthlyCheckModalOpen} onHide={closeMonthlyCheckTable} />

        </div>
    );
}

export default MyAssets;
