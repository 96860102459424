import { Height } from '@material-ui/icons';
import MaterialTable from 'material-table'
import { useState, useEffect } from 'react';
import Api from '../../../../API';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import { CsvBuilder } from 'filefy';
import { Modal } from 'bootstrap';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

function AvailableTable() {
    const [t, i18n] = useTranslation('common');
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem("isAdmin"));
    const [selectedRows, setSelectedRows] = useState([]);

    const [tableData, setTableData] = useState([
        // {
        //     CategoryId: "1", AssetId: "1", AssetName: "Asus VivoBook 15", EmployeeId: "1", EmployeeName: "siam",
        //     IsOkay: "0",
        //     AssetDetails: "",
        //     AssetNeedApproval: "0",
        //     IsIdentifiable: "0",
        // },

    ])

    const columns = [
        {
            title: t('AvailableAssets.1'), field: "AssetId", emptyValue: () => <em>null</em>, filtering: false, defaultSort: "asc",
            headerStyle: { pointerEvents: "none", textAlign: "left", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('AvailableAssets.2'), field: "CategoryId", emptyValue: () => <em>null</em>, filtering: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('AvailableAssets.3'), field: "CategoryName", emptyValue: () => <em>null</em>, filtering: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('AvailableAssets.4'), field: "Model", emptyValue: () => <em>null</em>, filtering: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", },
            editComponent: ({ value, onChange }) => (
                <TextField
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    multiline
                    fullWidth
                />
            ),
        },
        {
            title: t('AvailableAssets.5'), field: "AssetNumber", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('AvailableAssets.6'), field: "SerialNumber", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
    ]

    // adding asset to my account
    const addToMyself = (data) => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        const assetId = data.AssetId;
        const emplyoeeId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const identifiable = data.IsIdentifiable;
        const categoryId = data.CategoryId;
        const model = data.Model;
        const comments = data.Comments;

        console.log(JSON.stringify(data))
        console.log('/assets/requestUserAsset')

        console.log("AssetId: " + assetId + ", EmplyoeeId: " + emplyoeeId + ", isIdentifiable: " + identifiable + ", categoryId: " + categoryId
            , "Model:" + model, "Comments:" + comments);

        Api({
            method: 'post',
            url: '/assets/requestUserAsset',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                AssetId: assetId,
                EmployeeId: emplyoeeId,
                IsIdentifiable: identifiable,
                CategoryId: categoryId,
                Model: model,
                Comments: comments,
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                alert("Requested");
                console.log("fetching table data");
                fetchTableData();
            } else {
                alert("Something went wrong");
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    // custom export data in CSV
    const customRowExport = () => {
        var name = ""
        var date = new Date()
        console.log("Available Assets List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)")
        name = "Available Assets List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        new CsvBuilder(name + ".csv")
            .setColumns(columns.map(col => col.title))
            .addRows(selectedRows.map(rowData => columns.map(col => rowData[col.field])))
            .exportFile();

    }

    const exportedFileName = () => {
        var name = ""
        var date = new Date()
        console.log(date)
        console.log(date.toISOString())
        console.log(date.toISOString().slice(0, -1))
        console.log("Available Assets List " + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " (" +
            date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds() + ")")
        name = "Available Assets List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        return name
    }

    // fetch table Data
    const fetchTableData = () => {
        setTableData([])

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        const emplyoeeId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const emplyoeeIsAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;


        console.log("EmplyoeeId: " + emplyoeeId);
        console.log("EmplyoeeIsAdmin:" + emplyoeeIsAdmin);


        console.log("/assets/availableAssets")
        Api({
            method: 'post',
            url: '/assets/availableAssets',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                // EmployeeIsAdmin: emplyoeeIsAdmin,
                // EmployeeId: emplyoeeId


            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success === 1) {
                console.log("successss")
                console.log("DATA FETCH: " + response.data.data);
                setTableData(response.data.data)
            } else {
                // console.log("ORE BABA")


            }

            console.log(response.data.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    useEffect(() => {
        //setId(APIIDData());

        setTableData([])
        console.log("/assets/availableAssets");
        console.log("EmployeeId: " + JSON.parse(localStorage.getItem('userDetails')).LoggedInId);

        fetchTableData();

    }, []);


    return (
        <div className="Table">

            <MaterialTable
                columns={columns} data={tableData} title="Available Assets Information"


                onSelectionChange={(rows) => {
                    console.log(rows);
                    setSelectedRows(rows);
                }}

                actions={[
                    {
                        icon: () => <AddCircleOutlineSharpIcon />,
                        tooltip: "Add asset to Myself",
                        onClick: (e, data) => {
                            if (window.confirm('Are you sure you wish to "Add" this item?')) {
                                addToMyself(data);
                                console.log(data);
                            }
                        },
                        position: "row",
                        selection: "toolbarOnSelect"
                    },
                    {
                        icon: 'download',
                        tooltip: "export data",
                        onClick: (e, data) => {
                            console.log(data);
                            customRowExport()
                        },
                    }
                ]}

                onRowClick={(e, rowData) => {
                    <>
                        <Modal
                            size="sg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Add new Asset
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <h2>test</h2>
                            </Modal.Body>


                            <Modal.Footer>
                                <button >Close</button>
                            </Modal.Footer>
                        </Modal>
                    </>
                }}

                options={{
                    sorting: true, search: true, searchFieldAlignment: "right", searchAutoFocus: false, searchFieldVariant: "standard",
                    filtering: false, emptyRowsWhenPaging: false,
                    paging: true, pageSizeOptions: [10, 20, 50, 100], pageSize: 10, paginationType: "stepped", paginationPosition: "top",
                    showFirstLastPageButtons: true,
                    exportButton: true, exportAllData: true,
                    addRowPosition: "first", actionsColumnIndex: -1,
                    selection: true, showSelectAllCheckbox: true, selectionProps: rowData => ({
                        color: "primary"
                    }),
                    grouping: false,
                    columnsButton: true,
                    rowStyle: (data, index) => index % 2 == 0 ? { background: null, wordWrap: 'break-word', } : { background: "#EEFEFF", wordWrap: 'break-word', },
                    headerStyle: {
                        position: "sticky",
                        top: "0",
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        width: "0rem",
                        paddingLeft: "0",
                        paddingRight: "0",
                        margin: "0",
                        backgroundColor: "#ADEFD1FF",
                    },
                    maxBodyHeight: "70vh",
                    tableLayout: "fixed",
                    exportFileName: exportedFileName(),
                }}

                localization={{
                    header: {
                        actions: t('ActionTitle.1')
                    }
                }}


            />
        </div>
    );
}

export default AvailableTable;
