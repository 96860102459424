import MaterialTable from 'material-table'
import { useEffect, useState } from 'react';
import Activities from '../Activities/Activities';
import AdminPanel from '../Admin/AdminPanel';
import AllAsset from '../Inventory/AllAsset';
import AssetDetails from '../Assets/AssetDetails';
import Profile from '../../Header/Profile/Profile';
import MyAssets from '../Home/MyAssets';
import RequestedAssets from '../Requests/RequestedAssets';
import Users from '../Users/Users';
import AvailableAssets from '../Available/AvailableAssets';

const DashBoard = props => {
  console.log(props.selectedTable); // 1,2,3,4,5,6,7
  const [selectedTable, setSelectTable] = useState(1);


  useEffect(() => {
    setSelectTable(props.selectedTable);
  }, [props])

  return (
    <>
      {selectedTable == 1 && <MyAssets />}
      {selectedTable == 2 && <Users />}
      {selectedTable == 3 && <AvailableAssets />}
      {selectedTable == 4 && <AssetDetails />}
      {selectedTable == 5 && <AdminPanel />}
      {selectedTable == 6 && <RequestedAssets />}
      {selectedTable == 7 && <AllAsset />}
      {selectedTable == 8 && <Activities />}
      {selectedTable == 9 && <Profile />}
    </>

  );
}

export default DashBoard;
