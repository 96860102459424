import { Height } from '@material-ui/icons';
import MaterialTable from 'material-table'
import { useState, useEffect } from 'react';
import Api from '../../../API';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import { CsvBuilder } from 'filefy';
import { useTranslation } from 'react-i18next';

function Activities() {
    const [t, i18n] = useTranslation('common');
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem("isAdmin"));
    const [selectedRows, setSelectedRows] = useState([])

    const [tableData, setTableData] = useState([
        // { CategoryId: "1", AssetId: "1", AssetName: "Asus VivoBook 15", EmployeeId: "1", EmployeeName: "siam" },
        // {
        //     ActivityId: "1",
        //     ActivityTime: "1",
        //     ActivityType: "Asus VivoBook 15",
        //     AssetId: "1",
        //     AssetName: "2021-10-07T01:24:28.000Z",
        //     EmployeeId: "4",
        //     EmployeeName: "1",
        // },

    ])

    const columns = [
        {
            title: t('Activities.1'), field: "ActivityId", emptyValue: () => <em>null</em>, filtering: false, defaultSort: "desc",
            headerStyle: { pointerEvents: "none", textAlign: "left", }
        },
        {
            title: t('Activities.2'), field: "AssetId", emptyValue: () => <em>null</em>, filtering: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('Activities.3'), field: "ActivityTime", emptyValue: () => <em>null</em>, defaultSort: "asc", filtering: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('Activities.4'), field: "ActivityType", emptyValue: () => <em>null</em>, filtering: false,
            lookup: { 0: "test", 1: "Assigned", 2: "Returned", 3: "Added", 4: "Expired" },
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('Activities.5'), field: "Name", emptyValue: () => <em>null</em>, filtering: true,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('Activities.6'), field: "EmployeeId", emptyValue: () => <em>null</em>, filtering: true,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('Activities.7'), field: "EmployeeName", emptyValue: () => <em>null</em>, filtering: true,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
    ]


    // custom export data in CSV
    const customRowExport = () => {
        var name = ""
        var date = new Date()
        console.log("Activity List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)")
        name = "Activity List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        new CsvBuilder(name + ".csv")
            .setColumns(columns.map(col => col.title))
            .addRows(selectedRows.map(rowData => columns.map(col => rowData[col.field])))
            .exportFile();

    }

    const exportedFileName = () => {
        var name = ""
        var date = new Date()
        console.log(date)
        console.log(date.toISOString())
        console.log(date.toISOString().slice(0, -1))
        console.log("Activity List " + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " (" +
            date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds() + ")")
        name = "Activity List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        return name
    }

    const fetchTableData = () => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        setTableData([])
        console.log("/activity/showActivityTable");
        Api({
            method: 'post',
            url: '/activity/showActivityTable',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                setTableData(response.data.data)
            } else {


            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }


    useEffect(() => {
        console.log("ActivitiesTable")
        //setId(APIIDData());
        fetchTableData()



    }, []);


    return (
        <div className="Table">

            <MaterialTable columns={columns} data={tableData} title="Activity Information"

                onSelectionChange={(rows) => {
                    console.log(rows);
                    setSelectedRows(rows);
                }}

                actions={[

                    {
                        icon: 'download',
                        tooltip: "export data",
                        onClick: (e, data) => {
                            console.log(data);
                            customRowExport()
                        },
                    }
                ]}

                options={{
                    sorting: true, search: true, searchFieldAlignment: "right", searchAutoFocus: false, searchFieldVariant: "standard",
                    filtering: false, emptyRowsWhenPaging: false,
                    paging: true, pageSizeOptions: [10, 20, 50, 100], pageSize: 10, paginationType: "stepped", paginationPosition: "top",
                    showFirstLastPageButtons: true,
                    exportButton: true, exportAllData: true,
                    addRowPosition: "first", actionsColumnIndex: -1,
                    selection: true, showSelectAllCheckbox: true, selectionProps: rowData => ({
                        color: "primary"
                    }),
                    grouping: false,
                    columnsButton: true,
                    rowStyle: (data, index) => index % 2 == 0 ? { background: null, wordWrap: 'break-word', } : { background: "#EEFEFF", wordWrap: 'break-word', },
                    headerStyle: {
                        position: "sticky",
                        top: "0",
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        width: "0rem",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        backgroundColor: "#ADEFD1FF",
                    },
                    maxBodyHeight: "65vh",
                    tableLayout: "fixed",
                    exportFileName: exportedFileName(),
                }}

                localization={{
                    header: {
                        actions: t('ActionTitle.1')
                    }
                }}
            />
        </div>
    );
}

export default Activities;
