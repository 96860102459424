import MaterialTable from 'material-table'
import { useState, useEffect } from 'react';
import Api from '../../../API';
import { CsvBuilder } from 'filefy';
import { useTranslation } from 'react-i18next';
import { Replay } from '@material-ui/icons';
import { TextField } from '@material-ui/core';

function Users() {
    const [t, i18n] = useTranslation('common');
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem("isAdmin"));
    const [selectedRows, setSelectedRows] = useState([]);

    const [tableData, setTableData] = useState([
        // {
        //     EmployeeId: 1,
        //     EmployeeName: "siam",
        //     EmployeePassword: "1234",
        //     EmployeeEmail: "siam@uxd.co.jp",
        //     EmployeeFullName: "Fahim Siam",
        //     EmployeeBatchId: "202109",
        //     EmployeeIsAdmin: "0",
        //     EmployeeNumber: "1872627888",
        //     EmployeeAddress: "OLD DHAKA"
        // },

    ])

    const columns =
        isAdmin > 1 ?
            [
                {
                    title: t('Users.1'), field: "EmployeeId", emptyValue: () => <em>null</em>, filtering: false, editable: false,
                    headerStyle: { pointerEvents: "none", textAlign: "left", }
                },
                {
                    title: t('Users.2'), field: "EmployeeName", emptyValue: () => <em>null</em>, filtering: true,
                    render: rowData => {
                        return rowData.MonthlyCheckConfirm == 1 ? <p style={{ color: "#000", padding: "0px 20px 0px 20px", margin: "0px 0px 0px 0px" }}>{rowData.EmployeeName}</p> :
                            <div style={{ background: "#FDD2BF", color: "#8E0505", fontWeight: "bold", width: "fit-content", padding: "4px 20px 4px 20px", }}>{rowData.EmployeeName}</div>
                    },
                    headerStyle: { textAlign: "left", paddingLeft: "15px", }
                },
                {
                    title: t('Users.3'), field: "EmployeeEmail", emptyValue: () => <em>null</em>, filtering: true, editable: false,
                    headerStyle: { textAlign: "left", paddingLeft: "15px", }
                },
                {
                    title: t('Users.4'), field: "EmployeeFullName", emptyValue: () => <em>null</em>, filtering: true,
                    headerStyle: { textAlign: "left", paddingLeft: "15px", },
                    editComponent: ({ value, onChange }) => (
                        <TextField
                            onChange={(e) => onChange(e.target.value)}
                            value={value}
                            multiline
                            fullWidth
                        />
                    ),
                },
                {
                    title: t('Users.5'), field: "EmployeeBatchId", emptyValue: () => <em>null</em>, filtering: true,
                    headerStyle: { textAlign: "left", paddingLeft: "15px", }
                },
                {
                    title: t('Users.6'), field: "EmployeeIsAdmin", emptyValue: () => <em>null</em>, filtering: true, lookup: { 0: "User", 1: "Moderator", 2: "Admin" },
                    headerStyle: { textAlign: "left", paddingLeft: "15px", }
                },
                {
                    title: t('Users.7'), field: "EmployeeNumber", emptyValue: () => <em>null</em>, filtering: true,
                    headerStyle: { textAlign: "left", paddingLeft: "15px", }, multiline: true
                },
                {
                    title: t('Users.8'), field: "EmployeeAddress", emptyValue: () => <em>null</em>, filtering: true,
                    headerStyle: { textAlign: "left", paddingLeft: "15px", },
                    editComponent: ({ value, onChange }) => (
                        <TextField
                            onChange={(e) => onChange(e.target.value)}
                            value={value}
                            multiline
                            fullWidth
                        />
                    ),
                },
                {
                    title: t('Users.9'), field: "EditAssetFlag", emptyValue: () => <em>false</em>, defaultSort: "desc", filtering: true, lookup: { 0: "False", 1: "true" },
                    render: rowData => {
                        return rowData.EditAssetFlag == 0 ? <p style={{ fontWeight: "normal", width: "fit-content", padding: "4px 20px 4px 4px", marginTop: "0px", marginBottom: "0px" }}>{rowData.EditAssetFlag === 1 ? "True" : "False"}</p> :
                            <div style={{ background: "#acff99", color: "#4ca638", fontWeight: "bold", width: "fit-content", padding: "4px 20px 4px 4px", marginTop: "0px", marginBottom: "0px" }}>{rowData.EditAssetFlag === 1 ? "True" : "False"}</div>
                    },
                    headerStyle: { textAlign: "left", paddingLeft: "15px", }
                },
                {
                    title: t('Users.7'), field: "MonthlyCheckConfirm", emptyValue: () => <em>null</em>, filtering: true, hidden: true, defaultSort: "desc",
                    headerStyle: { textAlign: "left", paddingLeft: "15px", }
                },
            ]
            : [
                {
                    title: t('Users.1'), field: "EmployeeId", emptyValue: () => <em>null</em>, filtering: false, editable: false,
                    headerStyle: { pointerEvents: "none", textAlign: "left", }
                },
                {
                    title: t('Users.2'), field: "EmployeeName", emptyValue: () => <em>null</em>, filtering: true,
                    headerStyle: { textAlign: "left", paddingLeft: "15px", }
                },
                {
                    title: t('Users.3'), field: "EmployeeEmail", emptyValue: () => <em>null</em>, filtering: true, editable: false,
                    headerStyle: { textAlign: "left", paddingLeft: "15px", }
                },
                {
                    title: t('Users.4'), field: "EmployeeFullName", emptyValue: () => <em>null</em>, filtering: true,
                    headerStyle: { textAlign: "left", paddingLeft: "15px", }
                },
                {
                    title: t('Users.5'), field: "EmployeeBatchId", emptyValue: () => <em>null</em>, filtering: true,
                    headerStyle: { textAlign: "left", paddingLeft: "15px", }
                },
                {
                    title: t('Users.6'), field: "EmployeeIsAdmin", emptyValue: () => <em>null</em>, filtering: true, editable: false, lookup: { 0: "User", 1: "Moderator", 2: "Admin" },
                    headerStyle: { textAlign: "left", paddingLeft: "15px", }
                },
                {
                    title: t('Users.7'), field: "EmployeeNumber", emptyValue: () => <em>null</em>, filtering: true,
                    headerStyle: { textAlign: "left", paddingLeft: "15px", }
                },
                {
                    title: t('Users.8'), field: "EmployeeAddress", emptyValue: () => <em>null</em>, filtering: true,
                    headerStyle: { textAlign: "left", paddingLeft: "15px", },
                    editComponent: ({ value, onChange }) => (
                        <TextField
                            onChange={(e) => onChange(e.target.value)}
                            value={value}
                            multiline
                            fullWidth
                        />
                    ),
                },
            ]




    // custom export data in CSV
    const customRowExport = () => {
        var name = ""
        var date = new Date()
        console.log("User List " + date.getDate() + "-" + (date.getMonth() + 1) + 1 + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)")
        name = "User List " + date.getDate() + "-" + (date.getMonth() + 1) + 1 + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        new CsvBuilder(name + ".csv")
            .setColumns(columns.map(col => col.title))
            .addRows(selectedRows.map(rowData => columns.map(col => rowData[col.field])))
            .exportFile();

    }

    const exportedFileName = () => {
        var name = ""
        var date = new Date()
        console.log("User List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)")
        name = "User List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        return name
    }

    const resetUserPassword = (rowDetails) => {
        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        console.log("Password Reset")

        console.log(rowDetails)
        console.log("EmployeePassword: 123456")
        console.log("/admin/resetPassword")
        Api({
            method: 'post',
            url: '/admin/resetPassword',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                EmployeeId: rowDetails.EmployeeId,
                EmployeePassword: "123456",
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                fetchTableData()
                alert("Success")
            } else {
                alert("Something went wrong");

            }
            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });

    }

    const editUserData = (rowDetails) => {
        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        if (rowDetails.EmployeeName !== "" && rowDetails.EmployeeEmail !== "" && rowDetails.EmployeePassword !== "" && rowDetails.EmployeeIsAdmin !== "") {
            console.log("Required Filled")

            console.log(rowDetails)
            console.log("/admin/adminEditUserInformation")
            Api({
                method: 'post',
                url: '/admin/adminEditUserInformation',
                data: {
                    LoggedInId: loggedInId,
                    EmployeeIsAdmin: isAdmin,
                    token: token,

                    EmployeeId: rowDetails.EmployeeId,
                    EmployeeName: rowDetails.EmployeeName,
                    EmployeeEmail: rowDetails.EmployeeEmail,
                    EditIsAdmin: rowDetails.EmployeeIsAdmin,
                    EmployeeFullName: rowDetails.EmployeeFullName,
                    EmployeeBatchId: rowDetails.EmployeeBatchId,
                    EmployeeNumber: rowDetails.EmployeeNumber,
                    EmployeeAddress: rowDetails.EmployeeAddress,
                    EditAssetFlag: rowDetails.EditAssetFlag,
                }
            }).then(response => {
                if (response.data.status == 201) {
                    // window.open("https://www.google.com", "_self");
                    localStorage.clear();
                    window.location.reload();
                }
                else if (response.data.success == 1) {
                    fetchTableData()
                    alert("Success")
                } else {
                    alert("Something went wrong");

                }
                console.log(response.data);
            }).catch(function (error) {
                console.log(error);
                // alert("Something went wrong");
                alert(error);
            });

        } else {
            {
                var error = ""
                if (rowDetails.EmployeeName == "") error += "Username "
                if (rowDetails.EmployeeEmail == "") error += "Email "
                if (rowDetails.EmployeePassword == "") error += "Password "
                if (rowDetails.EmployeeIsAdmin == "") error += "Admin Level "
                if (rowDetails.EmployeeIsAdmin < 0 && rowDetails.EmployeeIsAdmin > 2) error += "Admin Level Issue"
                console.log("Required not Full Filled")
                alert(error)
            }
        }
    }

    const deleteUserData = (rowDetails) => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        console.log(rowDetails)
        console.log("EmployeeId: " + rowDetails.EmployeeId)
        console.log("/admin/deleteUserInfo")

        Api({
            method: 'post',
            url: '/admin/deleteUserInfo',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                EmployeeId: rowDetails.EmployeeId,
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                fetchTableData()
                alert("Success")
            } else {
                alert(response.data.message);

            }
            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });

    }

    const fetchTableData = () => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        console.log("/admin/showAllUsers");
        Api({
            method: 'post',
            url: '/admin/showAllUsers',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                setTableData([]);
                setTableData(response.data.data)

            } else {
                alert("Something went wrong");
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    useEffect(() => {

        fetchTableData()

    }, []);


    return (
        <div className="Table">

            <MaterialTable columns={columns} data={tableData} title="Employee Information"

                editable={

                    {
                        ...isAdmin === "2" ? {
                            // onRowAdd: (newRow) => new Promise((reslove, reject) => {
                            //     if (newRow.name != null && newRow.phone != null) {
                            //         setTableData([...tableData, newRow])
                            //         reslove()
                            //     } else {
                            //         console.log("Field required")
                            //         reject()
                            //     }
                            // }),
                            onRowUpdate: (newRow, oldRow) => new Promise((reslove, reject) => {
                                editUserData(newRow)
                                reslove()
                            }),
                            onRowDelete: (selectedRow) => new Promise((reslove, reject) => {
                                deleteUserData(selectedRow)
                                reslove()
                            })
                        } : {
                            ...isAdmin === "1" ? {
                                // onRowAdd: (newRow) => new Promise((reslove, reject) => {
                                //     if (newRow.name != null && newRow.phone != null) {
                                //         setTableData([...tableData, newRow])
                                //         reslove()
                                //     } else {
                                //         console.log("Field required")
                                //         reject()
                                //     }
                                // }),
                                onRowUpdate: (newRow, oldRow) => new Promise((reslove, reject) => {
                                    editUserData(newRow)
                                    reslove()
                                })

                            } : null
                        }
                    }



                }

                onSelectionChange={(rows) => {
                    console.log(rows);
                    setSelectedRows(rows);
                }}

                actions={

                    isAdmin > 0 ? [
                        {
                            icon: 'download',
                            tooltip: "export data",
                            onClick: (e, data) => {
                                console.log(data);
                                customRowExport()
                            },
                        },
                        {
                            icon: () => <Replay />,
                            tooltip: "Reset",
                            onClick: (e, data) => {
                                if (window.confirm('"Reset" this user\'s password?')) {
                                    console.log("confirmed")
                                    resetUserPassword(data);
                                    console.log(data);
                                }
                            },
                            position: "row",
                            selection: "toolbarOnSelect"
                        },
                    ] : [
                        {
                            icon: 'download',
                            tooltip: "export data",
                            onClick: (e, data) => {
                                console.log(data);
                                customRowExport()
                            },
                        },
                    ]
                }

                options={{
                    sorting: true, search: true, searchFieldAlignment: "right", searchAutoFocus: false, searchFieldVariant: "standard",
                    filtering: false, emptyRowsWhenPaging: false,
                    paging: true, pageSizeOptions: [10, 20, 50, 100], pageSize: 10, paginationType: "stepped", paginationPosition: "top",
                    showFirstLastPageButtons: true,
                    exportButton: true, exportAllData: true,
                    addRowPosition: "first", actionsColumnIndex: -1,
                    selection: true, showSelectAllCheckbox: true, selectionProps: rowData => ({
                        color: "primary"
                    }),
                    grouping: false,
                    columnsButton: true,
                    rowStyle: (data, index) => data.MonthlyCheckConfirm === 0 ? { background: null } : index % 2 === 0 ? { background: null, wordWrap: 'break-word', } : { background: "#EEFEFF", wordWrap: 'break-word', },
                    headerStyle: {
                        position: "sticky",
                        top: "0",
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        width: "0rem",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        backgroundColor: "#ADEFD1FF",
                    },
                    maxBodyHeight: "70vh",
                    tableLayout: "fixed",
                    exportFileName: exportedFileName(),
                }}

                localization={{
                    header: {
                        actions: t('ActionTitle.1')
                    }
                }}
            />
        </div>
    );
}


export default Users;