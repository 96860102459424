import MaterialTable from 'material-table'
import { useState, useEffect } from 'react';
import Api from '../../../API';
import { CsvBuilder } from 'filefy';
import { Modal, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import { Replay } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

function CategoryDetailsTable({ show, onHide, category }) {
    const [t, i18n] = useTranslation('common');

    const [tableData, setTableData] = useState([
        // { AssetId: "Maruf", AssetName: "asd@gmail.com", EmployeeId: "012345", UsageStart: "Male", IsOkay: "Dhaka", AssetDetails: "Asset details" },
    ])
    const [categoryName, setCategoryName] = useState("")

    const columns = [
        {
            title: t('AssetDetails.2'), field: "AssetId", emptyValue: () => <em>null</em>, defaultSort: "asc", filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('AssetDetails.3'), field: "CategoryId", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "20%", textAlign: "left", }
        },
        {
            title: t('AssetDetails.5'), field: "Model", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "20%", textAlign: "left", },
            editComponent: ({ value, onChange }) => (
                <TextField
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    multiline
                    fullWidth
                />
            ),
        },
        {
            title: t('AssetDetails.6'), field: "EmployeeId", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "20%", textAlign: "left", }
        },

        {
            title: t('AssetDetails.8'), field: "AssetNumber", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('AssetDetails.9'), field: "SerialNumber", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('AssetDetails.10'), field: "Cpu", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('AssetDetails.11'), field: "Ram", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('AssetDetails.12'), field: "Hdd", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('AssetDetails.13'), field: "Ssd", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('AssetDetails.14'), field: "OperatingSystem", emptyValue: () => <em>null</em>, filtering: false, editable: (_, rowData) => rowData && (rowData.SerialNumber === null || rowData.SerialNumber === "") && rowData.EditAssetFlag === 1,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('AssetDetails.1'), field: "IsOkay", emptyValue: () => <em>null</em>, filtering: false, lookup: { 0: "Defect", 1: "OK" },
            headerStyle: { textAlign: "left", paddingLeft: "15px", }, cellStyle: { width: "5%", textAlign: "left", }
        },
        {
            title: t('AssetDetails.18'), field: "Comments", emptyValue: () => <em>null</em>, filtering: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
            editComponent: ({ value, onChange }) => (
                <TextField
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    multiline
                    fullWidth
                />
            ),
        },
        {
            title: t('AssetDetails.19'), field: "Purpose", emptyValue: () => <em>null</em>, filtering: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
            editComponent: ({ value, onChange }) => (
                <TextField
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    multiline
                    fullWidth
                />
            ),
        },
    ]

    const [selectedRows, setSelectedRows] = useState([]);


    const editTableData = (rowDetails) => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;
        
        console.log(rowDetails)
        const assetId = rowDetails.AssetId

        const assetName = rowDetails.AssetName
        const assetDetails = rowDetails.AssetDetails
        const comments = rowDetails.Comments
        const isOkay = rowDetails.IsOkay

        console.log(assetName + " " + assetDetails + " " + comments + " " + isOkay + " ")

        console.log("AssetId: " + assetId)
        console.log("/admin/editIndividualAsset")
        Api({
            method: 'post',
            url: '/admin/editIndividualAsset',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                AssetId: assetId,
                AssetName: assetName,
                AssetDetails: assetDetails,
                Comments: comments,
                IsOkay: isOkay,

            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                alert("Edited Successfully")
                fetchTableData()
            } else {
                alert("Something went wrong");
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    // custom export data in CSV
    const customRowExport = () => {
        var name = ""
        var date = new Date()
        console.log("Asset Details List (" + categoryName + ")" + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)")
        name = "Asset Details List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        new CsvBuilder(name + ".csv")
            .setColumns(columns.map(col => col.title))
            .addRows(selectedRows.map(rowData => columns.map(col => rowData[col.field])))
            .exportFile();

    }

    const exportedFileName = () => {
        var name = ""
        var date = new Date()
        console.log(date)
        console.log(date.toISOString())
        console.log(date.toISOString().slice(0, -1))
        console.log("Asset Details List " + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " (" +
            date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds() + ")")
        name = "Asset Details List (" + categoryName + " list)" + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        return name
    }


    // adding asset to my account
    const returnFromMyslef = (data) => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        const assetId = data.AssetId;
        const identifiable = data.IsIdentifiable;
        const emplyoeeId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;

        console.log("AssetId: " + assetId + ", EmplyoeeId: " + emplyoeeId + ", identifiable: " + identifiable);

        Api({
            method: 'post',
            url: '/assets/returnUserAsset',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                AssetId: assetId,
                EmployeeId: emplyoeeId,
                IsIdentifiable: identifiable
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                // setTableData(response.data.data)
                fetchTableData()
            } else {
                alert("Something went wrong");
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });


        //load the following data after the response
        console.log("fetching table data");
        fetchTableData();

    }

    // fetch table Data
    const fetchTableData = () => {
        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;


        console.log(category)
        console.log("/assets/AssetsCategoryWise")
        Api({
            method: 'post',
            url: '/assets/AssetsCategoryWise',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                CategoryId: category
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success === 1) {
                setTableData([])
                setTableData(response.data.data)
                setCategoryName(response.data.categoryName)
            } else {
                alert("Something went wrong");
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    useEffect(() => {
        //setId(APIIDData());

        setTableData([])
        console.log("/assets/userIndividualAssets");
        console.log("EmployeeId: " + JSON.parse(localStorage.getItem('userDetails')).LoggedInId);
        fetchTableData();


    }, [category]);





    return (

        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body
                style={{ background: "white", width: "1000px" }}
            >

                <MaterialTable columns={columns} data={tableData} title="Asset Details"


                    onSelectionChange={(rows) => {
                        console.log(rows);
                        setSelectedRows(rows);
                    }}

                    options={{
                        sorting: true, search: true, searchFieldAlignment: "right", searchAutoFocus: false, searchFieldVariant: "standard",
                        filtering: false, emptyRowsWhenPaging: false,
                        paging: true, pageSizeOptions: [10, 20, 50, 100], pageSize: 10, paginationType: "stepped", paginationPosition: "top",
                        showFirstLastPageButtons: true,
                        exportButton: true, exportAllData: true,
                        addRowPosition: "first", actionsColumnIndex: -1,
                        selection: false, showSelectAllCheckbox: true, selectionProps: rowData => ({
                            color: "primary"
                        }),
                        grouping: false,
                        columnsButton: true,
                        rowStyle: (data, index) => data.IsOkay == 0 ? { background: "#FDD2BF", wordWrap: 'break-word', } : index % 2 === 0 ? { background: null, wordWrap: 'break-word', } : { background: "#EEFEFF", wordWrap: 'break-word', },
                        headerStyle: {
                            position: "sticky",
                            top: "0",
                            fontSize: "1.1rem",
                            fontWeight: "500",
                            width: "0rem",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            backgroundColor: "#ADEFD1FF",
                        },
                        maxBodyHeight: "60vh",
                        tableLayout: "auto",
                        exportFileName: exportedFileName(),

                        // rowStyle: {
                        //   wordWrap: 'break-word',
                        // },
                        // cellStyle: {
                        //   width: 20,
                        //   maxWidth: 20
                        // }
                        // editCellStyle: {
                        //   maxWidth: 5,
                        //   wordBreak: "break-word",
                        //   whiteSpace: 'normal',
                        //   wordWrap: 'break-word'
                        // }
                    }}

                    localization={{
                        header: {
                            actions: t('ActionTitle.1')
                        }
                    }}
                />

            </Modal.Body>

            <Modal.Footer style={{ background: "white", width: "1000px" }}>
                <button className="buttonClose" onClick={onHide}>Close</button>
            </Modal.Footer>
        </Modal>
    );
}

export default CategoryDetailsTable;
