import { Cancel, Check, Height } from '@material-ui/icons';
import MaterialTable from 'material-table'
import { useState, useEffect } from 'react';
import Api from '../../../API';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import { CsvBuilder } from 'filefy';
import { useTranslation } from 'react-i18next';

function RequestedAssets() {
    const [t, i18n] = useTranslation('common');
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem("isAdmin"));
    const [isAccept, setIsAccept] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const [tableData, setTableData] = useState([
        // { CategoryId: "1", AssetId: "1", AssetName: "Asus VivoBook 15", AssetDetails: "siam", EmployeeId: "1", },

    ])

    const columns = [
        {
            title: t('RequestedAssets.1'), field: "AssetId", emptyValue: () => <em>null</em>, filtering: false, defaultSort: "asc",
            headerStyle: { pointerEvents: "none", textAlign: "left", }
        },
        {
            title: t('RequestedAssets.2'), field: "CategoryId", emptyValue: () => <em>null</em>, filtering: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }
        },
        {
            title: t('RequestedAssets.3'), field: "Model", emptyValue: () => <em>null</em>, filtering: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }
        },
        {
            title: t('RequestedAssets.4'), field: "EmployeeId", emptyValue: () => <em>null</em>, filtering: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }
        },
        {
            title: t('RequestedAssets.5'), field: "AssetNumber", emptyValue: () => <em>null</em>, filtering: true,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }
        },
        {
            title: t('RequestedAssets.8'), field: "SerialNumber", emptyValue: () => <em>null</em>, filtering: true,
            headerStyle: { textAlign: "left", paddingLeft: "15px", }
        },
        {
            title: t('RequestedAssets.6'), field: "Request", emptyValue: () => <em>null</em>, filtering: true, lookup: { "1": "Adding", "2": "Returning" },
            headerStyle: { textAlign: "left", paddingLeft: "15px", }
        },
    ]



    // approveReturnRequest
    const approveReturnRequest = (data) => {
        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        const assetId = data.AssetId;
        const emplyoeeId = data.EmployeeId;
        const request = data.Request;
        const categoryId = data.CategoryId;
        const responseRequest = 1;

        console.log("AssetId: " + assetId + ", categoryId: " + categoryId + ", responseRequest: "
            + responseRequest + ", request: " + request + ", emplyoeeId: " + emplyoeeId)

        console.log("/admin/approveReturnRequest")
        Api({
            method: 'post',
            url: '/admin/approveReturnRequest',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                AssetId: assetId,
                Request: request,
                CategoryId: categoryId,
                ResponseRequest: responseRequest,
                EmployeeId: emplyoeeId
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                // setTableData(response.data.data)
                fetchTableData()
            } else {


            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });

    }


    // approveAddRequest
    const approveAddRequest = (data) => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        const assetId = data.AssetId;
        const emplyoeeId = data.EmployeeId;
        // const emplyoeeId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const request = data.Request;
        const categoryId = data.CategoryId;
        const responseRequest = 1;

        console.log("AssetId: " + assetId + ", categoryId: " + categoryId + ", responseRequest: "
            + responseRequest + ", request: " + request + ", emplyoeeId: " + emplyoeeId)

        console.log('/admin/approveAddRequest')
        Api({
            method: 'post',
            url: '/admin/approveAddRequest',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                AssetId: assetId,
                Request: request,
                CategoryId: categoryId,
                ResponseRequest: responseRequest,
                EmployeeId: emplyoeeId
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                // setTableData(response.data.data)
                fetchTableData()
            } else {
                alert(response.data.message);

            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }


    // denyReturnRequest
    const denyReturnRequest = (data) => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        const assetId = data.AssetId;
        const emplyoeeId = data.EmployeeId;
        const request = data.Request;
        var responseRequest = 0;

        console.log("AssetId: " + assetId + ", EmplyoeeId: " + emplyoeeId + ", isAccept: " + responseRequest + ", request: " + request);

        console.log("/admin/denyReturnRequest")
        Api({
            method: 'post',
            url: '/admin/denyReturnRequest',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                AssetId: assetId,
                Request: request,
                EmployeeId: emplyoeeId,
                ResponseRequest: responseRequest
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                // setTableData(response.data.data)
                fetchTableData()
            } else {


            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });

    }

    // denyAddRequest
    const denyAddRequest = (data) => {
        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        const assetId = data.AssetId;
        const emplyoeeId = data.EmployeeId;
        const request = data.Request;
        var responseRequest = 0;

        console.log("AssetId: " + assetId + ", EmplyoeeId: " + emplyoeeId + ", ResponseRequest: " + responseRequest + ", request: " + request);

        console.log("/admin/denyAddRequest")
        Api({
            method: 'post',
            url: '/admin/denyAddRequest',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                AssetId: assetId,
                Request: request,
                EmployeeId: emplyoeeId,
                ResponseRequest: isAccept
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                // setTableData(response.data.data)
                fetchTableData()
            } else {


            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });

    }



    // custom export data in CSV
    const customRowExport = () => {
        var name = ""
        var date = new Date()
        console.log("Requested Assets List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)")
        name = "Requested Assets List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        new CsvBuilder(name + ".csv")
            .setColumns(columns.map(col => col.title))
            .addRows(selectedRows.map(rowData => columns.map(col => rowData[col.field])))
            .exportFile();

    }

    const exportedFileName = () => {
        var name = ""
        var date = new Date()
        console.log(date)
        console.log(date.toISOString())
        console.log(date.toISOString().slice(0, -1))
        console.log("Requested Assets List " + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " (" +
            date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds() + ")")
        name = "Requested Assets List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        return name
    }

    // fetch table Data
    const fetchTableData = () => {
        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        setTableData([]);
        console.log("/admin/showAllRequests")
        Api({
            method: 'post',
            url: '/admin/showAllRequests',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                setTableData(response.data.data)
            } else {
                alert("something went wrong")
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    useEffect(() => {
        //setId(APIIDData());
        console.log("RequestTable")

        setTableData([])
        console.log("/assets/availableAssets");
        console.log("EmployeeId: " + JSON.parse(localStorage.getItem('userDetails')).LoggedInId);

        fetchTableData();

    }, []);


    return (
        <div className="Table">

            <MaterialTable columns={columns} data={tableData} title="Requested Assets Information"

                onSelectionChange={(rows) => {
                    console.log(rows);
                    setSelectedRows(rows);
                }}

                actions={[
                    {
                        icon: () => <Check />,
                        tooltip: "Approve",
                        onClick: (e, data) => {
                            if (window.confirm('Are you sure you wish to "Approve" this item?')) {
                                setIsAccept(true);
                                if (data.Request == "2") {
                                    approveReturnRequest(data);
                                } else {
                                    approveAddRequest(data);
                                }
                                console.log(data);
                                console.log(JSON.stringify(data.Request))
                            }
                        },
                        position: "row",
                        selection: "toolbarOnSelect"
                    },
                    {
                        icon: () => <Cancel />,
                        tooltip: "Reject",
                        onClick: (e, data) => {
                            setIsAccept(false);
                            if (data.Request == "2") {
                                denyReturnRequest(data);
                            } else {
                                denyAddRequest(data);
                            }
                            console.log(data);
                            console.log(JSON.stringify(data.Request))
                        },
                        position: "row",
                        selection: "toolbarOnSelect"
                    },
                    {
                        icon: 'download',
                        tooltip: "export data",
                        onClick: (e, data) => {
                            console.log(data);
                            customRowExport()
                        },
                    }
                ]}

                options={{
                    sorting: true, search: true, searchFieldAlignment: "right", searchAutoFocus: false, searchFieldVariant: "standard",
                    filtering: false, emptyRowsWhenPaging: false,
                    paging: true, pageSizeOptions: [10, 20, 50, 100], pageSize: 10, paginationType: "stepped", paginationPosition: "top",
                    showFirstLastPageButtons: true,
                    exportButton: true, exportAllData: true,
                    addRowPosition: "first", actionsColumnIndex: -1,
                    selection: true, showSelectAllCheckbox: true, selectionProps: rowData => ({
                        color: "primary"
                    }),
                    grouping: false,
                    columnsButton: true,
                    rowStyle: (data, index) => index % 2 == 0 ? { background: null, wordWrap: 'break-word', } : { background: "#EEFEFF", wordWrap: 'break-word', },
                    headerStyle: {
                        position: "sticky",
                        top: "0",
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        width: "0rem",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        backgroundColor: "#ADEFD1FF",
                    },
                    maxBodyHeight: "65vh",
                    tableLayout: "fixed",
                    exportFileName: exportedFileName(),
                }}

                localization={{
                    header: {
                        actions: t('ActionTitle.1')
                    }
                }}
            />
        </div>
    );
}

export default RequestedAssets;
