import MaterialTable from 'material-table'
import { useState, useEffect } from 'react';
import Api from '../../../../API';
import { CsvBuilder } from 'filefy';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import { Replay } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

function HistoryTable() {
    const [t, i18n] = useTranslation('common');

    const [tableData, setTableData] = useState([
        // { AssetId: "Maruf", AssetName: "asd@gmail.com", EmployeeId: "012345", UsageStart: "Male", IsOkay: "Dhaka", AssetDetails: "Asset details" },
        // {
        //     "HistoryId": 28,
        //     "HistoryDate": "2021-10-26T18:00:00.000Z",
        //     "CategoryId": 10,
        //     "CategoryName": "Pen",
        //     "AssetExpired": 0,
        //     "AssetUsed": 0,
        //     "AssetQuantity": 0
        // }
    ])

    const columns = [
        {
            title: t('AdminPanel.1'), field: "CategoryName", emptyValue: () => <em>null</em>, defaultSort: "asc", filtering: false, editable: false,
            headerStyle: { pointerEvents: "none", paddingLeft: "15px", textAlign: "left", },
        },
        {
            title: t('AdminPanel.2'), field: "HistoryDate", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('AdminPanel.3'), field: "AssetQuantity", emptyValue: () => <em>null</em>, filtering: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('AdminPanel.4'), field: "AssetUsed", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('AdminPanel.5'), field: "AssetExpired", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
    ]

    const [selectedRows, setSelectedRows] = useState([]);


    // custom export data in CSV
    const customRowExport = () => {
        var name = ""
        var date = new Date()
        console.log("History List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)")
        name = "History List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        new CsvBuilder(name + ".csv")
            .setColumns(columns.map(col => col.title))
            .addRows(selectedRows.map(rowData => columns.map(col => rowData[col.field])))
            .exportFile();

    }

    const exportedFileName = () => {
        var name = ""
        var date = new Date()
        console.log(date)
        console.log(date.toISOString())
        console.log(date.toISOString().slice(0, -1))
        console.log("History List " + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " (" +
            date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds() + ")")
        name = "History List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        return name
    }


    // adding asset to my account
    const returnFromMyslef = (data) => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        const assetId = data.AssetId;
        const identifiable = data.IsIdentifiable;
        const emplyoeeId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;

        console.log("AssetId: " + assetId + ", EmplyoeeId: " + emplyoeeId + ", identifiable: " + identifiable);

        Api({
            method: 'post',
            url: '/assets/returnUserAsset',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                AssetId: assetId,
                EmployeeId: emplyoeeId,
                IsIdentifiable: identifiable
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                // setTableData(response.data.data)
                fetchTableData()
            } else {
                alert("Something went wrong");
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });


        //load the following data after the response
        console.log("fetching table data");
        fetchTableData();

    }

    // fetch table Data
    const fetchTableData = () => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        console.log("/activity/showHistoryTable")
        Api({
            method: 'post',
            url: '/activity/showHistoryTable',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success === 1) {
                setTableData([])
                setTableData(response.data.data)
            } else {
                alert("Something went wrong");
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    useEffect(() => {
        //setId(APIIDData());

        fetchTableData();


    }, []);





    return (
        <div className="Table">

            <MaterialTable columns={columns} data={tableData} title="History Records"

                onSelectionChange={(rows) => {
                    console.log(rows);
                    setSelectedRows(rows);
                }}

                options={{
                    sorting: true, search: true, searchFieldAlignment: "right", searchAutoFocus: false, searchFieldVariant: "standard",
                    filtering: false, emptyRowsWhenPaging: false,
                    paging: true, pageSizeOptions: [10, 20, 50, 100], pageSize: 10, paginationType: "stepped", paginationPosition: "top",
                    showFirstLastPageButtons: true,
                    exportButton: true, exportAllData: true,
                    addRowPosition: "first", actionsColumnIndex: -1,
                    selection: false, showSelectAllCheckbox: true, selectionProps: rowData => ({
                        color: "primary"
                    }),
                    grouping: false,
                    columnsButton: true,
                    rowStyle: (data, index) => index % 2 == 0 ? { background: null, wordWrap: 'break-word', } : { background: "#EEFEFF", wordWrap: 'break-word', },
                    headerStyle: {
                        position: "sticky",
                        top: "0",
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        width: "0rem",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        backgroundColor: "#ADEFD1FF",
                    },
                    maxBodyHeight: "70vh",

                    tableLayout: "fixed",
                    exportFileName: exportedFileName(),
                    // rowStyle: {
                    //   wordWrap: 'break-word',
                    // },
                    // cellStyle: {
                    //   width: 20,
                    //   maxWidth: 20
                    // }
                    // editCellStyle: {
                    //   maxWidth: 5,
                    //   wordBreak: "break-word",
                    //   whiteSpace: 'normal',
                    //   wordWrap: 'break-word'
                    // }
                }}

                localization={{
                    header: {
                        actions: t('ActionTitle.1')
                    }
                }}
            />
        </div>
    );
}

export default HistoryTable;
