import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import { AccountCircle } from "@material-ui/icons";
import Api from "../../../API";

function Profile({ show, onHide }) {

    const [showProfile, setShowProfile] = useState(true);

    const [newUserDetails, setNewUserDetails] = useState({
        EmployeeName: "", EmployeeEmail: "", EmployeePassword: "", EmployeeIsAdmin: "",
        EmployeeFullName: "", EmployeeBatchId: "", EmployeeNumber: "", EmployeeAddress: "", EmployeeNewPassword: ""
    });
    const [name, setName] = useState("");

    const fetchProfileData = () => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        const emplyoeeId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;

        setNewUserDetails({
            EmployeeName: "", EmployeeEmail: "", EmployeeNewPassword: "", EmployeePassword: "", EmployeeIsAdmin: "",
            EmployeeFullName: "", EmployeeBatchId: "", EmployeeNumber: "", EmployeeAddress: ""
        })

        console.log("/users/showUserIndividualInformation")
        Api({
            method: 'post',
            url: '/users/showUserIndividualInformation',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                EmployeeId: emplyoeeId,
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                // alert("Success")
                console.log("userInfo " + JSON.stringify(response.data));
                console.log("userInfo " + JSON.stringify(response.data.data.EmployeeName));


                console.log("EmployeeIsAdmin: " + response.data.data.EmployeeIsAdmin)

                setNewUserDetails(
                    {
                        ...newUserDetails,
                        EmployeeName: response.data.data.EmployeeName,
                        EmployeeEmail: response.data.data.EmployeeEmail,
                        EmployeeIsAdmin: response.data.data.EmployeeIsAdmin,
                        EmployeeNewPassword: "",
                        EmployeeFullName: response.data.data.EmployeeFullName,
                        EmployeeBatchId: response.data.data.EmployeeBatchId,
                        EmployeeNumber: response.data.data.EmployeeNumber,
                        EmployeeAddress: response.data.data.EmployeeAddress
                    },
                )


            } else {
                alert("Error")

            }
            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    useEffect(() => {
        fetchProfileData();
    }, [show])

    const SubmitHandler = () => {
        //API call here
        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        if (newUserDetails.EmployeePassword === "" || newUserDetails.EmployeePassword === null ) {
            alert("Please provide your \"Current Password\" to Edit your profile")
        }
        else if (newUserDetails.EmployeeName !== "" && newUserDetails.EmployeeEmail !== "" && newUserDetails.EmployeeIsAdmin !== "") {
            console.log("Required Filled")
            const emplyoeeId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
            console.log(newUserDetails)
            console.log("/users/editUserInformation")
            Api({
                method: 'post',
                url: '/users/editUserInformation',
                data: {
                    LoggedInId: loggedInId,
                    EmployeeIsAdmin: isAdmin,
                    token: token,
    
                    EmployeeId: emplyoeeId,
                    EmployeeName: newUserDetails.EmployeeName,
                    EmployeeEmail: newUserDetails.EmployeeEmail,
                    EmployeePassword: newUserDetails.EmployeePassword,
                    EmployeeNewPassword: newUserDetails.EmployeeNewPassword,
                    EditIsAdmin: newUserDetails.EmployeeIsAdmin,
                    EmployeeFullName: newUserDetails.EmployeeFullName,
                    EmployeeBatchId: newUserDetails.EmployeeBatchId,
                    EmployeeNumber: newUserDetails.EmployeeNumber,
                    EmployeeAddress: newUserDetails.EmployeeAddress
                }
            }).then(response => {
                if (response.data.status == 201) {
                    // window.open("https://www.google.com", "_self");
                    localStorage.clear();
                    window.location.reload();
                }
                else if (response.data.success == 1) {
                    alert("Success")
                    fetchProfileData()
                    onHide()
                } else {
                    alert("Error")

                }
                console.log(response.data);
            }).catch(function (error) {
                console.log(error);
                // alert("Something went wrong");
                alert(error);
            });

        } else {
            {
                var error = ""
                if (newUserDetails.EmployeeName == "") error += "Username "
                if (newUserDetails.EmployeeEmail == "") error += "Email "
                if (newUserDetails.EmployeePassword == "") error += "Password "
                if (newUserDetails.EmployeeIsAdmin == "") error += "Admin Level "
                if (newUserDetails.EmployeeIsAdmin < 0 && newUserDetails.EmployeeIsAdmin > 2) error += "Admin Level Issue"
                console.log("Required not Full Filled")
                alert(error)
            }
        }
        console.log(newUserDetails);
    };


    return (
        <>

            <Modal
                show={show}
                size="sg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <AccountCircle fontSize="large" />&nbsp;&nbsp;Edit Profile
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>


                    <TextField label='Username' placeholder='Enter Username' type='text' fullWidth required focused='true'
                        onChange={e => setNewUserDetails({ ...newUserDetails, EmployeeName: e.target.value })} value={newUserDetails.EmployeeName} />

                    <TextField label='Email' placeholder='Enter Your Email' type='email' fullWidth required disabled
                        onChange={e => setNewUserDetails({ ...newUserDetails, EmployeeEmail: e.target.value })} value={newUserDetails.EmployeeEmail} />

                    <TextField label='New Password' placeholder='Enter New Password' type='password' fullWidth autoComplete='new-password'
                        onChange={e => setNewUserDetails({ ...newUserDetails, EmployeeNewPassword: e.target.value })} value={newUserDetails.EmployeeNewPassword} />

                    <TextField label='Enter Admin Level' placeholder='Enter Admin Level (0=user, 1=Moderator, 2=Admin)' type='text' fullWidth required disabled
                        onChange={e => setNewUserDetails({ ...newUserDetails, EmployeeIsAdmin: e.target.value })} value={newUserDetails.EmployeeIsAdmin == "2" ? "Admin" : newUserDetails.EmployeeIsAdmin == "1" ? "Moderator" : "User"} />

                    <TextField label='Fullname (optional)' placeholder='Enter Fullname (optional)' type='text' fullWidth
                        onChange={e => setNewUserDetails({ ...newUserDetails, EmployeeFullName: e.target.value })} value={newUserDetails.EmployeeFullName} />

                    <TextField label='BatchId (optional)' placeholder='Enter BatchId (optional)' type='text' fullWidth disabled
                        onChange={e => setNewUserDetails({ ...newUserDetails, EmployeeBatchId: e.target.value })} value={newUserDetails.EmployeeBatchId} />

                    <TextField label='Phone Number (optional)' placeholder='Enter Your Phone Number (optional)' type='text' fullWidth
                        onChange={e => setNewUserDetails({ ...newUserDetails, EmployeeNumber: e.target.value })} value={newUserDetails.EmployeeNumber} />

                    <TextField label='Address (optional)' placeholder='Address (optional)' type='text' fullWidth
                        onChange={e => setNewUserDetails({ ...newUserDetails, EmployeeAddress: e.target.value })} value={newUserDetails.EmployeeAddress} />

                    <TextField label='Enter Your Password' placeholder='Enter Your Password' type='password' fullWidth required margin='8 0 0 0'
                        onChange={e => setNewUserDetails({ ...newUserDetails, EmployeePassword: e.target.value })} value={newUserDetails.EmployeePassword} />

                </Modal.Body>

                <Modal.Footer>
                    <button className="submitButton" onClick={SubmitHandler}>Submit</button>
                    <button className="buttonClose" onClick={onHide}>Close</button>
                </Modal.Footer>

            </Modal>
        </>
    )
}

export default Profile;