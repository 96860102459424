import { Height } from '@material-ui/icons';
import MaterialTable from 'material-table'
import React, { useState, useEffect, useRef } from 'react';
import Api from '../../../API';
import AvailableTable from './AvailableTable/AvailableTable';
import Multiselect from 'multiselect-react-dropdown';

function AvailableAssets() {
    const [selectedAssetData, setSelectedAssetData] = useState()

    const selectedData = React.createRef();
    const resetSelectedData = () => {
        selectedData.current.resetSelectedValues();
    }

    const [assetData, setAssetData] = useState([
        // {
        //     "AssetId": 109,
        //     "Model": "ispahani"
        // },
        // {
        //     "AssetId": 111,
        //     "Model": "washroom tissue"
        // }
    ])
    const [globalActivityData, setGlobalActivityData] = useState([])

    const getGlobalActivityHistory = () => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        console.log("getting categoryList")
        console.log("/activity/showGlobalActivityTable");
        console.log("EmployeeId: " + JSON.parse(localStorage.getItem('userDetails')).LoggedInId);
        Api({
            method: 'post',
            url: '/activity/showGlobalActivityTable',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                setGlobalActivityData([])
                setGlobalActivityData(response.data.data)
            } else {
                alert("Something went wrong");
            }

            console.log("response")
            console.log(response.data.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    const getAssetData = () => {


        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        // console.log(addNewAsset)
        console.log("getting categoryList")
        console.log("/assets/availableGlobalAssets");
        console.log("EmployeeId: " + JSON.parse(localStorage.getItem('userDetails')).LoggedInId);
        Api({
            method: 'post',
            url: '/assets/availableGlobalAssets',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                setAssetData([])
                setAssetData(response.data.data)
                // getCategoryList(response.data.data)
            } else {
                // alert("Something went wrong");
            }

            console.log("response")
            console.log(response.data.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    const selectedAssetId = (selectedList, RemovedList) => {
        // setAddNewAsset({ ...addNewAsset, CategoryId: selectedList[0].CategoryId, IsIdentifiable: selectedList[0].IsIdentifiable })
        console.log("selected")
        console.log(selectedList[0].AssetId)
        console.log(selectedList[0].IsIdentifiable)
        console.log(JSON.parse(localStorage.getItem('userDetails')).LoggedInId)

        setSelectedAssetData(selectedList)
    }

    const removeSelected = () => {

    }

    const SubmitHandler = () => {
        //API call here
        // setAddNewAsset({...addNewAsset, CategoryId: categoryId, IsIdentifiable: isIdentifiable})


        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        var assetId = ""
        var employeeId = ""
        var isIdentifiable = ""
        var categoryId = ""

        if (selectedAssetData != null && selectedAssetData.length != 0) {
            console.log(selectedAssetData)
            assetId = selectedAssetData[0].AssetId
            employeeId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId
            isIdentifiable = selectedAssetData[0].IsIdentifiable
            categoryId = selectedAssetData[0].CategoryId
        }


        if (window.confirm('Are you sure you wish to "Warn" about this item?')) {


            if (assetId !== "" && employeeId !== "" && isIdentifiable !== "") {
                console.log("assetId: " + assetId + ", employeeId: " + employeeId + ", isIdentifiable: " + isIdentifiable)
                console.log("/assets/requestUserAsset")
                Api({
                    method: 'post',
                    url: '/assets/requestUserAsset',
                    data: {

                        LoggedInId: loggedInId,
                        EmployeeIsAdmin: isAdmin,
                        token: token,

                        AssetId: assetId,
                        EmployeeId: employeeId,
                        IsIdentifiable: isIdentifiable,
                        CategoryId: categoryId
                    }
                }).then(response => {
                    if (response.data.status == 201) {
                        // window.open("https://www.google.com", "_self");
                        localStorage.clear();
                        window.location.reload();
                    }
                    else if (response.data.success == 1) {
                        alert("Success");
                        getAssetData()
                        resetSelectedData()
                        setSelectedAssetData()


                    } else {
                        alert("Error")

                    }
                    console.log(response.data);
                }).catch(function (error) {
                    console.log(error);
                    // alert("Something went wrong");
                    alert(error);
                });

            }

        }
    };



    useEffect(() => {
        getGlobalActivityHistory()
        getAssetData()

    }, []);


    return (

        <>
            <div className="AvailableBody">
                <ul className="availableBodyLeftSide ml-0 pl-0">
                    <li>
                        <AvailableTable />
                    </li>
                </ul>


                <ul className="availableBodyRightSide mb-0 pl-3 pr-2">
                    {
                        globalActivityData.length > 0 ?
                            <div className="availableData">
                                {globalActivityData.map(x => <div key={x.Name}>{x.Name} last Refilled on {x.ActivityTime}<br></br><br></br></div>)}
                            </div>
                            :
                            null
                    }
                    
                    <div className=" mt-3">
                        Request for Global Assets
                    </div>

                    <Multiselect
                        isObject={true}
                        selectedValues={[]}
                        singleSelect={true}
                        options={assetData}
                        displayValue="CategoryName"
                        // selectedValues={[`${categoryList[0].toString()}`]}
                        onRemove={function noRefCheck() { }}
                        onSelect={selectedAssetId}
                        ref={selectedData}
                    />

                    <button className="availableButton" onClick={SubmitHandler}>
                        Submit Warning
                    </button>

                </ul>
            </div>

        </>
    );
}

export default AvailableAssets;
