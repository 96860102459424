import { TextField } from "@material-ui/core";
import { AddToQueueSharp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Modal, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import Api from "../../../../API";
import Multiselect from 'multiselect-react-dropdown';

function AddNewAsset({ show, onHide }) {

    const [showAssetQuantity, setShowAssetQuantity] = useState(false)

    const [addNewAsset, setAddNewAsset] = useState({
        CategoryId: "", Model: "", Comments: "", IsIdentifiable: "", Quantity: "1",
        AssetNumber: "", SerialNumber: "", Cpu: "", Ram: "", Hdd: "", Ssd: "",
        OperatingSystem: "", Purpose: ""

    });

    const [categoryData, setCategoryData] = useState([])
    const [categoryId, setCategoryId] = useState()
    const [isIdentifiable, setIsIdentifiable] = useState()

    const [id, setId] = useState("");

    useEffect(() => {
        // setAddNewAsset({ CategoryId: "", AssetName: "", Comments: "", AssetDetails: "", IsIdentifiable: "" })
        getCategoryData()
        setAddNewAsset({
            ...addNewAsset,
            CategoryId: "", Model: "", Comments: "", IsIdentifiable: "", Quantity: "1",
            AssetNumber: "", SerialNumber: "", Cpu: "", Ram: "", Hdd: "", Ssd: "",
            OperatingSystem: "", Purpose: ""
        })
    }, [show]);

    const getCategoryData = () => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        console.log(addNewAsset)
        console.log("getting categoryList")
        console.log("/admin/showAllAssetCategory");
        console.log("EmployeeId: " + JSON.parse(localStorage.getItem('userDetails')).LoggedInId);
        Api({
            method: 'post',
            url: '/admin/showAllAssetCategory',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();;
            }
            else if (response.data.success == 1) {
                setCategoryData([])
                setCategoryData(response.data.data)
            } else {
                alert("Something went wrong");
            }

            console.log("response")
            console.log(response.data.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    const selectedCategoryId = (selectedList, RemovedList) => {

        console.log(selectedList[0])

        setAddNewAsset({ ...addNewAsset, CategoryId: selectedList[0].CategoryId, IsIdentifiable: selectedList[0].IsIdentifiable })
        console.log(addNewAsset)

    }

    const SubmitHandler = () => {
        //API call here
        // setAddNewAsset({...addNewAsset, CategoryId: categoryId, IsIdentifiable: isIdentifiable})

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        console.log(addNewAsset)
        console.log("/admin/addAsset")

        if (addNewAsset.IsIdentifiable === 1) {
            console.log("Identifiable: 1")

            if (addNewAsset.CategoryId !== "" && addNewAsset.Model !== "" && addNewAsset.AssetNumber !== "" && addNewAsset.SerialNumber !== "") {
                Api({
                    method: 'post',
                    url: '/admin/addAsset',
                    data: {

                        LoggedInId: loggedInId,
                        EmployeeIsAdmin: isAdmin,
                        token: token,

                        CategoryId: addNewAsset.CategoryId,
                        Model: addNewAsset.Model,
                        Comments: addNewAsset.Comments,
                        IsIdentifiable: addNewAsset.IsIdentifiable,
                        Quantity: addNewAsset.Quantity,
                        AssetNumber: addNewAsset.AssetNumber,
                        SerialNumber: addNewAsset.SerialNumber,
                        Cpu: addNewAsset.Cpu,
                        Ram: addNewAsset.Ram,
                        Hdd: addNewAsset.Hdd,
                        Ssd: addNewAsset.Ssd,
                        OperatingSystem: addNewAsset.OperatingSystem,
                        Purpose: addNewAsset.Purpose,
                    }
                }).then(response => {
                    if (response.data.status == 201) {
                        // window.open("https://www.google.com", "_self");
                        localStorage.clear();
                        window.location.reload();
                    }
                    else if (response.data.success == 1) {
                        alert("Success");
                        onHide();
                    } else {
                        alert("Error")

                    }
                    console.log(response.data);
                }).catch(function (error) {
                    console.log(error);
                    alert("Something went wrong");
                    alert(error);
                });
            } else {
                alert("Please Fillup all required fields")
            }

        } else if (addNewAsset.IsIdentifiable === 0 || addNewAsset.IsIdentifiable === 2) {
            console.log("Identifiable: 0||2")

            if (addNewAsset.CategoryId !== "" && addNewAsset.Model !== "") {
                Api({
                    method: 'post',
                    url: '/admin/addAsset',
                    data: {

                        LoggedInId: loggedInId,
                        EmployeeIsAdmin: isAdmin,
                        token: token,

                        CategoryId: addNewAsset.CategoryId,
                        Model: addNewAsset.Model,
                        Comments: addNewAsset.Comments,
                        IsIdentifiable: addNewAsset.IsIdentifiable,
                        Quantity: addNewAsset.Quantity,
                    }
                }).then(response => {
                    if (response.data.status == 201) {
                        // window.open("https://www.google.com", "_self");
                        localStorage.clear();
                        window.location.reload();
                    }
                    else if (response.data.success == 1) {
                        alert("Success");
                        onHide();
                    } else {
                        alert("Error")

                    }
                    console.log(response.data);
                }).catch(function (error) {
                    console.log(error);
                    alert("Something went wrong");
                    alert(error);
                });
            } else {
                if (addNewAsset.CategoryId == "") {
                    alert("Please select a category")
                } else {
                    alert("Please Enter Model")
                }
            }

        } else {
            alert("something Went wrong")
        }

        console.log(addNewAsset);
    };

    const CancelHandler = () => {

    }

    return (
        <>
            <Modal
                show={show}
                size="sg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add new Asset
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Multiselect
                        isObject={true}
                        singleSelect={true}
                        options={categoryData}
                        displayValue="CategoryName"
                        // selectedValues={[`${categoryList[0].toString()}`]}
                        onRemove={function noRefCheck() { }}
                        onSearch={function noRefCheck() { }}
                        onSelect={selectedCategoryId}
                    />

                    <TextField label='Enter Model' placeholder='Enter Model' type='text' fullWidth required
                        onChange={e => setAddNewAsset({ ...addNewAsset, Model: e.target.value })} value={addNewAsset.Model} />

                    <TextField label='Enter Comments' placeholder='Enter Comments' type='text' fullWidth
                        onChange={e => setAddNewAsset({ ...addNewAsset, Comments: e.target.value })} value={addNewAsset.Comments} />

                    {
                        addNewAsset.IsIdentifiable == 0 || addNewAsset.IsIdentifiable == 2 ?
                            <TextField label='Quantity' placeholder='Quantity' type='text' fullWidth
                                onChange={e => setAddNewAsset({ ...addNewAsset, Quantity: e.target.value })} value={addNewAsset.Quantity} />
                            :
                            <>
                                <TextField label='Enter Asset Number' placeholder='Enter Asset Number' type='text' fullWidth required
                                    onChange={e => setAddNewAsset({ ...addNewAsset, AssetNumber: e.target.value })} value={addNewAsset.AssetNumber} />

                                <TextField label='Enter Serial Number' placeholder='Enter Serial Number' type='text' fullWidth required
                                    onChange={e => setAddNewAsset({ ...addNewAsset, SerialNumber: e.target.value })} value={addNewAsset.SerialNumber} />

                                <TextField label='Enter Cpu' placeholder='Enter Cpu' type='text' fullWidth
                                    onChange={e => setAddNewAsset({ ...addNewAsset, Cpu: e.target.value })} value={addNewAsset.Cpu} />

                                <TextField label='Enter Ram' placeholder='Enter Ram' type='text' fullWidth
                                    onChange={e => setAddNewAsset({ ...addNewAsset, Ram: e.target.value })} value={addNewAsset.Ram} />

                                <TextField label='Enter Hdd' placeholder='Enter Hdd' type='text' fullWidth
                                    onChange={e => setAddNewAsset({ ...addNewAsset, Hdd: e.target.value })} value={addNewAsset.Hdd} />

                                <TextField label='Enter Ssd' placeholder='Enter Ssd' type='text' fullWidth
                                    onChange={e => setAddNewAsset({ ...addNewAsset, Ssd: e.target.value })} value={addNewAsset.Ssd} />

                                <TextField label='Enter Operating System' placeholder='Enter OperatingSystem' type='text' fullWidth
                                    onChange={e => setAddNewAsset({ ...addNewAsset, OperatingSystem: e.target.value })} value={addNewAsset.OperatingSystem} />

                                <TextField label='Enter Purpose' placeholder='Enter Purpose' type='text' fullWidth
                                    onChange={e => setAddNewAsset({ ...addNewAsset, Purpose: e.target.value })} value={addNewAsset.Purpose} />
                            </>
                    }


                </Modal.Body>


                <Modal.Footer>
                    <button className="submitButton" onClick={SubmitHandler}>Submit</button>
                    <button className="buttonClose" onClick={onHide}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddNewAsset;
