import { useState, useEffect, useRef } from "react";
import './Notification/DropDownNotification.css';
import './AdminToggle/AdminToggle.css';
import './LanguageToggle/LanguageToogle.css';
import './Notification/NotificationBell.css';

import { AccountCircle, NotificationsActiveSharp } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import Api from '../../API';
import LanguageToogle from './LanguageToggle/LanguageToogle'

import Select from "react-select";

//Sound
import notification00 from "../../soundFiles/notification00.wav"
import notification01 from "../../soundFiles/notification01.wav"
import notification02 from "../../soundFiles/notification02.wav"
import notification03 from "../../soundFiles/notification03.wav"
import Profile from "./Profile/Profile";


function Header({ logout }) {
    const [t, i18n] = useTranslation('common');
    const adminLevelOption = [{ label: "user", value: 0 }, { label: "admin", value: 2 }]
    const moderatorLevelOption = [{ label: "user", value: 0 }, { label: "moderator", value: 1 }]

    const [adminLevelSelected, setAdminLevelSelected] = useState({ label: "admin", value: 2 })
    const [moderatorLevelSelected, setModeratorLevelSelected] = useState({ label: "moderator", value: 1 })

    //region Admin Toggle
    const adminToggle = (value) => {
        console.log(value)

        var userAdminLevel = ((JSON.parse(localStorage.getItem("userDetails"))).EmployeeIsAdmin)
        console.log("userAdminLevel: " + userAdminLevel)

        if (userAdminLevel === 2) setAdminLevelSelected(value)
        else setModeratorLevelSelected(value)


        if (value.value === 0) {
            console.log("AdminLevel: 0")
            localStorage.setItem('isAdmin', 0)
            window.location.reload();
        } else {
            console.log("AdminLevel: " + userAdminLevel)
            localStorage.setItem('isAdmin', userAdminLevel)
            window.location.reload();
        }
    }

    const adminCheckFirstTime = () => {
        var adminLevel = localStorage.getItem("isAdmin")
        var userAdminLevel = ((JSON.parse(localStorage.getItem("userDetails"))).EmployeeIsAdmin)
        console.log("AdminLevel: " + adminLevel + ", userAdminLevel: " + userAdminLevel)

        if (adminLevel === "1") {
            console.log("AdminLevel: " + userAdminLevel)
            setModeratorLevelSelected({ label: "moderator", value: userAdminLevel })
        } else if (adminLevel === "2") {
            console.log("AdminLevel: " + userAdminLevel)
            setAdminLevelSelected({ label: "admin", value: userAdminLevel })
        } else {
            console.log("AdminLevel: " + 0)
            setAdminLevelSelected({ label: "user", value: 0 })
            setModeratorLevelSelected({ label: "user", value: 0 })
        }
    }
    //endregion

    //Notification Audio Play
    //region Audio
    const notification00Audio = new Audio(notification00)
    const notification01Audio = new Audio(notification01)
    const notification02Audio = new Audio(notification02)
    const notification03Audio = new Audio(notification03)

    const playSound = (audioFile) => {
        audioFile.play();
        setTimeout(function () {
            audioFile.pause()
        }, 5000);

    };
    //endregion


    const [isProfileOpen, setIsProfileOpen] = useState(false);

    const [notificationData, setNotificationData] = useState([])
    const [notificationCount, setNotificationCount] = useState(0)


    //dropdown Notification menu
    //#region notification

    const [dropdownState, setDropdownState] = useState(false);
    const [dropdownValue, setDropdownValue] = useState("");

    const fetchNotificationData = () => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdminMain = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        console.log("/notification/showNotificationTable");
        let isAdmin = localStorage.getItem("isAdmin");
        if (isAdmin != 0) {
            return (

                Api({
                    method: 'post',
                    url: '/notification/showNotificationTable',
                    data: {

                        LoggedInId: loggedInId,
                        EmployeeIsAdmin: isAdminMain,
                        token: token,

                    }
                }).then(response => {
                    if (response.data.status == 201) {
                        window.location.reload();
                        // window.open("https://www.google.com", "_self");
                        localStorage.clear();
                    }
                    else if (response.data.success == 1) {
                        setNotificationData([]);
                        setNotificationData(response.data.data);

                        console.log(response.data.data.length);
                        //console.log(response.data.data[0].NotificationType);
                        //console.log("ddd",response.data.data[0].SeenFlag);


                        if (notificationCount < response.data.data.length) {

                            setNotificationCount(response.data.data.length)

                            if (response.data.data[0].NotificationType == '0' && response.data.data[0].SeenFlag == 0) {
                                console.log("Notification Type: 0");

                                playSound(notification00Audio);
                            }
                            else if (response.data.data[0].NotificationType == '1' && response.data.data[0].SeenFlag == 0) {
                                console.log("Notification Type: 1");

                                playSound(notification01Audio);
                            }
                            else if (response.data.data[0].NotificationType == '2' && response.data.data[0].SeenFlag == 0) {
                                console.log("Notification Type: 2");

                                playSound(notification02Audio);
                            }
                            else {
                                console.log("Notification Type: " + response.data.data[0].NotificationType);
                            }
                        }
                        else {

                            setNotificationCount(response.data.data.length)
                        }



                    } else {
                        // alert("Something went wrong");
                        setNotificationCount(0)
                    }

                    console.log(response.data);
                }).catch(function (error) {
                    console.log(error);
                    // alert("Something went wrong");
                    alert(error);
                })
            )
        }
        else {

            return null
        }




    }



    const setNotificationSeen = (data) => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        const notificationId = data;
        console.log("NotificationId: " + notificationId)
        console.log("/notification/notificationSeen")
        Api({
            method: 'post',
            url: '/notification/notificationSeen',
            data: {
                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                NotificationId: notificationId
            }
        }).then(response => {
            if (response.data.status == 201) {

                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                fetchNotificationData();
            } else {
                alert("Something went wrong");
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }


    const handleDropdownClick = () => {
        setDropdownState(!dropdownState);
    };
    const handleSetDropdownValue = (value) => {
        if (window.confirm("Mark this notification as 'SEEN'?")) {

            setDropdownValue(value);
            setNotificationSeen(value)
            setDropdownState(!dropdownState);
        }
    };

    //#endregion

    //#region ProfileModal
    const openProfileModal = () => {
        console.log("Create Clicked");
        console.log(isProfileOpen);
        setIsProfileOpen(true);
        console.log(isProfileOpen);
    };
    const closeProfileModal = () => {
        setIsProfileOpen(false);
    };
    //#endregion


    //#region autoAPIcall
    function Counter() {
        const [count, setCount] = useState(0);

        useInterval(() => {
            // this function is called all the time
            setCount(count + 1);
            console.log("counted: " + count)
            fetchNotificationData()
            console.log(notificationData)
        }, 10000);

        return <h1></h1>;
    }

    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest function.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }
    //#endregion


    useEffect(() => {

        fetchNotificationData()
        adminCheckFirstTime()

    }, []);




    return (
        <>

            {(localStorage.getItem('isLoggedIn') === "true") ? (
                <>
                    < div >
                        <img
                            className="logo-container-header"
                            src="uxbd_logo.png"
                            alt="UX-BD"
                        />



                        <div className="HeaderDetails">

                            {

                                ((JSON.parse(localStorage.getItem("userDetails"))).EmployeeIsAdmin) === 2 ?
                                    <Select
                                        className="mr-2"
                                        options={adminLevelOption}
                                        value={adminLevelSelected}
                                        onChange={value => adminToggle(value)}
                                        defaultValue={
                                            ((JSON.parse(localStorage.getItem("userDetails"))).EmployeeIsAdmin) === "2" ?
                                                { label: "admin", value: 2 }
                                                : { label: "user", value: 0 }
                                        }
                                    />
                                    : ((JSON.parse(localStorage.getItem("userDetails"))).EmployeeIsAdmin) === 1 ?
                                        <Select
                                            className="mr-2"
                                            options={moderatorLevelOption}
                                            value={moderatorLevelSelected}
                                            onChange={value => adminToggle(value)}
                                            defaultValue={
                                                (localStorage.getItem("isAdmin")) === "0" ?
                                                    { label: "user", value: 0 }
                                                    : { label: "moderator", value: 1 }
                                            }
                                        />
                                        : null
                            }


                            &nbsp;&nbsp;&nbsp;&nbsp;{t('Header.1')}:&nbsp;&nbsp;<LanguageToogle />

                            <h6 className="HeaderUserDetails">

                                <span>{(JSON.parse(localStorage.getItem('userDetails'))).EmployeeEmail}</span>{" "}

                                <Profile show={isProfileOpen} onHide={closeProfileModal} />
                                <AccountCircle className=" ml-2 " fontSize="large" onClick={openProfileModal} />

                                {(JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin != "0" && notificationCount === 0) ?
                                    <NotificationsActiveSharp onClick={handleDropdownClick} className={"notification"}>
                                        {dropdownValue === "" ? "Dropdown" : dropdownValue}
                                    </NotificationsActiveSharp>
                                    : notificationCount > 0 ?
                                        <span class="bell fa fa-bell" onClick={handleDropdownClick} data-notificationCount={notificationCount}>
                                        </span>
                                        :
                                        null

                                }

                                {/* Counter is to enable auto calling the notification function */}
                                {
                                    JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin != 0 ?
                                        <Counter />
                                        : null
                                }
                                {/* <Counter /> */}


                                <div
                                    className={`dropdown-items ${dropdownState ? "isVisible" : "isHidden"
                                        }`}
                                >

                                    {notificationData.length == 0 ?
                                        <div >
                                            <div >
                                                No Notification available
                                            </div>
                                        </div>
                                        :

                                        notificationData.map((data) => (

                                            data.NotificationType == "2" ?
                                                <div className="dropdown-item" key={data.NotificationId}
                                                    onClick={() =>
                                                        handleSetDropdownValue(data.NotificationId)
                                                    }>
                                                    <div
                                                        className="dropdown__link"
                                                    >
                                                        {data.NotificationId}. {data.EmployeeName} requested for {data.Name}
                                                    </div>
                                                </div>
                                                :
                                                data.NotificationType == "1" ?
                                                    <div className="dropdown-item" key={data.NotificationId}
                                                        onClick={() =>
                                                            handleSetDropdownValue(data.NotificationId)
                                                        }>
                                                        <div
                                                            className="dropdown__link"
                                                        >
                                                            {data.NotificationId}. {data.EmployeeName} has notified {data.Name} as Damaged
                                                        </div>
                                                    </div>
                                                    :
                                                    data.NotificationType == "0" ?
                                                        <div className="dropdown-item" key={data.NotificationId}
                                                            onClick={() =>
                                                                handleSetDropdownValue(data.NotificationId)
                                                            }>
                                                            <div
                                                                className="dropdown__link"
                                                            >
                                                                {data.NotificationId}. {data.Name} has reached warning quantity
                                                            </div>
                                                        </div>
                                                        : null

                                        ))

                                    }

                                </div>



                                <button className="buttonClose mx-4" onClick={logout} >Logout</button>
                            </h6>

                        </div>
                    </div >
                </>
            ) : null}
        </>

    );
}

export default Header;