import { Height } from '@material-ui/icons';
import MaterialTable from 'material-table'
import { useState, useEffect } from 'react';
import Api from '../../../API';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import { CsvBuilder } from 'filefy';
import CategoryDetailsTable from './CategoryDetailsTable';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

function AllAsset() {
    const [t, i18n] = useTranslation('common');
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem("isAdmin"));
    const [selectedRows, setSelectedRows] = useState([])
    const [category, setCategory] = useState(1) //setting to prevent error as default

    const [tableData, setTableData] = useState([
        // { CategoryId: "1", AssetId: "1", AssetName: "Asus VivoBook 15", EmployeeId: "1", EmployeeName: "siam" },
        // {
        //     CategoryId: "1",
        //     CategoryName: "laptop",
        //     AssetQuantity: "16",
        //     AssetUsed: "6",
        //     AssetExpired: "3"
        // },

    ])

    const columns = [
        {
            title: t('AllAssets.1'), field: "CategoryId", emptyValue: () => <em>null</em>, defaultSort: "asc", sorting: true, filtering: false, editable: false,
            headerStyle: { pointerEvents: "none", textAlign: "left", },
        },
        {
            title: t('AllAssets.2'), field: "CategoryName", emptyValue: () => <em>null</em>, filtering: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('AllAssets.3'), field: "AssetQuantity", emptyValue: () => <em>null</em>, filtering: false, editable: false,
            render: rowData => {
                return rowData.WarningFlag == 0 ? <p style={{ color: "#9CC094", padding: "0px 20px 0px 20px", margin: "0" }}>{rowData.AssetQuantity}</p> :
                    <div style={{ background: "#FDD2BF", color: "#8E0505", fontWeight: "bold", width: "fit-content", padding: "0px 20px 0px 20px", margin: "0" }}>{rowData.AssetQuantity}</div>
            },
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('AllAssets.4'), field: "AssetUsed", emptyValue: () => <em>null</em>, filtering: true, editable: false,
            headerStyle: (rowData) => {
                return rowData.WarningFlag == 0 ? { background: "#9CC094" } : { background: "#E02401" }
            }, textAlign: "left", paddingLeft: "15px",
        },
        {
            title: t('AllAssets.5'), field: "AssetExpired", emptyValue: () => <em>null</em>, filtering: true, editable: false,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('AllAssets.6'), field: "WarningFlag", emptyValue: () => <em>null</em>, defaultSort: "desc", filtering: true, hidden: true,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
        {
            title: t('AllAssets.7'), field: "Quantity", emptyValue: () => <em>null</em>, filtering: true, hidden: true,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },

        },
        {
            title: t('AllAssets.6'), field: "WarningQuantity", emptyValue: () => <em>null</em>, filtering: true,
            headerStyle: { textAlign: "left", paddingLeft: "15px", },
        },
    ]


    // custom export data in CSV
    const customRowExport = () => {
        var name = ""
        var date = new Date()
        console.log("Inventory List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)")
        name = "Inventory List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        new CsvBuilder(name + ".csv")
            .setColumns(columns.map(col => col.title))
            .addRows(selectedRows.map(rowData => columns.map(col => rowData[col.field])))
            .exportFile();

    }

    const exportedFileName = () => {
        var name = ""
        var date = new Date()
        console.log(date)
        console.log(date.toISOString())
        console.log(date.toISOString().slice(0, -1))
        console.log("Inventory List " + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " (" +
            date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds() + ")")
        name = "Inventory List " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " (" +
            date.getHours() + "h-" + date.getMinutes() + "m)"

        return name
    }

    const editTableData = (rowDetails) => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        console.log(rowDetails)
        const categoryId = rowDetails.CategoryId
        const categoryName = rowDetails.CategoryName

        const warningQuantity = rowDetails.WarningQuantity
        console.log("categoryId" + categoryId + ", warningQuantity" + warningQuantity)

        console.log("/admin/editAssetCategory")
        Api({
            method: 'post',
            url: '/admin/editAssetCategory',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

                CategoryId: categoryId,
                CategoryName:categoryName,
                WarningQuantity: warningQuantity,
            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                alert("Edited Successfully")
                fetchTableData()
            } else {
                alert("Something went wrong");
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    const selectedCategory = (categoryId) => {
        // alert(index)
        console.log(categoryId)
        setCategory()
        setCategory(categoryId)
        showDynamicTable()
    }

    const fetchTableData = () => {

        const loggedInId = JSON.parse(localStorage.getItem('userDetails')).LoggedInId;
        const isAdmin = JSON.parse(localStorage.getItem('userDetails')).EmployeeIsAdmin;
        const token = JSON.parse(localStorage.getItem('userDetails')).token;

        console.log("AllAssetTable")

        console.log("/admin/showAllAssetCategory");
        console.log("EmployeeId: " + JSON.parse(localStorage.getItem('userDetails')).LoggedInId);
        Api({
            method: 'post',
            url: '/admin/showAllAssetCategory',
            data: {

                LoggedInId: loggedInId,
                EmployeeIsAdmin: isAdmin,
                token: token,

            }
        }).then(response => {
            if (response.data.status == 201) {
                // window.open("https://www.google.com", "_self");
                localStorage.clear();
                window.location.reload();
            }
            else if (response.data.success == 1) {
                setTableData([])
                setTableData(response.data.data)
            } else {
                alert("Something went wrong");
            }

            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
            // alert("Something went wrong");
            alert(error);
        });
    }

    useEffect(() => {
        //setId(APIIDData());

        fetchTableData()

    }, []);



    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const showDynamicTable = () => {
        console.log("Add new Clicked");
        console.log(addModalOpen);
        setAddModalOpen(true);
        console.log(isModalOpen);
    }
    const closeDynamicTable = () => {
        setAddModalOpen(false);
    }


    return (
        <div className="Table">
            {/* <h1 align="center">React-app</h1>
      <h2 align="center">Material-table</h2> */}

            <MaterialTable columns={columns} data={tableData} title="All Assets Information"
                editable={
                    {
                        ...isAdmin === "2" ? {
                            onRowUpdate: (newRow, oldRow) => new Promise((reslove, reject) => {
                                // const updatedData = [...tableData]
                                // updatedData[oldRow.tableData.id] = newRow
                                // setTableData(updatedData)
                                editTableData(newRow)
                                reslove()
                            }),
                            // onRowDelete: (selectedRow) => new Promise((reslove, reject) => {
                            //     const updatedData = [...tableData]
                            //     updatedData.splice(selectedRow.tableData.id, 1)
                            //     setTableData(updatedData)
                            //     reslove()
                            // })
                        } : {
                            ...isAdmin === "1" ? {
                                onRowUpdate: (newRow, oldRow) => new Promise((reslove, reject) => {
                                    // const updatedData = [...tableData]
                                    // updatedData[oldRow.tableData.id] = newRow
                                    // setTableData(updatedData)
                                    editTableData(newRow)
                                    reslove()
                                })
                            } : null
                        }
                    }
                }

                onSelectionChange={(rows) => {
                    console.log(rows);
                    setSelectedRows(rows);
                }}

                actions={[

                    {
                        icon: 'download',
                        tooltip: "export data",
                        onClick: (e, data) => {
                            console.log(data);
                            customRowExport()
                        },
                    }
                ]}

                onRowClick={(e, rowData) => selectedCategory(rowData.CategoryId)}

                options={{
                    sorting: true, search: true, searchFieldAlignment: "right", searchAutoFocus: false, searchFieldVariant: "standard",
                    filtering: false, emptyRowsWhenPaging: false,
                    paging: true, pageSizeOptions: [10, 20, 50, 100], pageSize: 10, paginationType: "stepped", paginationPosition: "top",
                    showFirstLastPageButtons: true,
                    exportButton: true, exportAllData: true,
                    addRowPosition: "first", actionsColumnIndex: -1,
                    selection: true, showSelectAllCheckbox: true, selectionProps: rowData => ({
                        color: "primary"
                    }),
                    grouping: false,
                    columnsButton: true,
                    rowStyle: (data, index) => data.WarningFlag == 1 ? { background: null } : index % 2 === 0 ? { background: null, wordWrap: 'break-word', } : { background: "#EEFEFF", wordWrap: 'break-word', },
                    headerStyle: {
                        position: "sticky",
                        top: "0",
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        width: "0rem",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        backgroundColor: "#ADEFD1FF",
                    },
                    maxBodyHeight: "65vh",
                    tableLayout: "fixed",
                    exportFileName: exportedFileName(),
                }}

                localization={{
                    header: {
                        actions: t('ActionTitle.1')
                    }
                }}
            />

            <div>
                <CategoryDetailsTable show={addModalOpen} onHide={closeDynamicTable} category={category} />
            </div>
        </div>
    );
}

export default AllAsset;
