import { MDBContainer } from "mdbreact";
import { useEffect } from "react";

const Footer = () => {
    return (
        <>

            <div className="footer-copyright text-center mt-1]">
                <MDBContainer fluid>
                    Copyright &copy;<a href="https://www.uxd.co.jp/uxbd/"> ULTRA-X BD LIMITED</a>, All rights reserved.
                </MDBContainer>
            </div>
        </>
    );
}

export default Footer;
